import { Environment } from './environment';
import ports from '../../ports.json';

const serviceLocations = (
  service: string,
  localPort: number,
): { [env in Environment]: string } => {
  let localHostname = 'localhost';
  if (
    typeof window !== 'undefined' &&
    window.location.href.startsWith('http') &&
    !window.location.hostname.includes('.')
  ) {
    localHostname = window.location.hostname;
  } else if (typeof process !== 'undefined' && process.env.LOCAL_HOSTNAME) {
    localHostname = process.env.LOCAL_HOSTNAME;
  }

  let locations = {
    local: `http://${localHostname}:${localPort}`,
    exp: `https://${service}.exp.wonder.dev`,
    dev: `https://${service}.dev.wonder.dev`,
    stage: `https://${service}.stage.wonder.dev`,
    prod: `https://${service}.wonder.inc`,
  };

  return locations;
};

export const dynascoreServiceLocations = serviceLocations(
  'dynascore',
  ports.DYNASCORE_BACKEND_PORT,
);
export const roamServiceLocations = serviceLocations(
  'roam',
  ports.ROAM_MIDDLEEND_PORT,
);
export const roamBackendServiceLocations = serviceLocations(
  'roambackend',
  ports.ROAM_BACKEND_PORT,
);
export const holoServiceLocations = serviceLocations(
  'holo',
  ports.ROAM_HOLO_PORT,
);
export const sectionServiceLocations = serviceLocations(
  'section',
  ports.ROAM_SECTION_PORT_1,
);
export const roamUserLocationServiceLocations = serviceLocations(
  'userlocation',
  ports.ROAM_USER_LOCATION_PORT,
);
export const roamOverworldServiceLocations = serviceLocations(
  'overworld',
  ports.ROAM_OVERWORLD_PORT,
);
export const roamChatServiceLocations = serviceLocations(
  'chat',
  ports.ROAM_CHAT_PORT,
);
export const roamNotificationServiceLocations = serviceLocations(
  'notification',
  ports.ROAM_NOTIFICATION_PORT,
);
export const gorushServiceLocations = serviceLocations(
  'gorush',
  ports.GORUSH_PORT,
);
export const accountsServiceLocations = serviceLocations(
  'accounts',
  ports.ACCOUNTS_BACKEND_PORT,
);
export const listeningServiceLocations = serviceLocations(
  'listening',
  ports.LISTENING_BACKEND_PORT,
);
export const mediaServiceLocations = listeningServiceLocations;

export const dingDongServiceLocations = serviceLocations(
  'dingdong',
  ports.DINGDONG_BACKEND_PORT,
);

export const crmServiceLocations = serviceLocations(
  'crm',
  ports.CRM_BACKEND_PORT,
);

export const allServiceLocations = [
  dynascoreServiceLocations,
  roamServiceLocations,
  roamBackendServiceLocations,
  holoServiceLocations,
  roamOverworldServiceLocations,
  roamChatServiceLocations,
  accountsServiceLocations,
  listeningServiceLocations,
  dingDongServiceLocations,
  crmServiceLocations,
];
