import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_DOMAIN } from './wonderConfig';
import Style from '../../../shared/features/dingdong/Style';

const styleApi = createApi({
  reducerPath: 'styleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_DOMAIN}/api/styles`,
  }),
  endpoints: (builder) => ({
    fetchAllStyles: builder.query<Style[], void>({
      query: () => ({
        url: '/all',
      }),
    }),
  }),
});
export default styleApi;
