import React, { useEffect, useState } from 'react';
import { useAppDispatch } from './app/hooks';
import { FeatureFlagActions } from './features/featureflags/featureFlagsSlice';
import axios from 'axios';
import { BASE_DOMAIN } from './services/wonderConfig';
import { logger } from '../../shared/infra/logger';

const fetchHash = async () => {
  return await axios.get(`${BASE_DOMAIN}/api/ld/hash`).then((response) => {
    return response.data as string;
  });
};

const Initializer: React.FC = () => {
  const dispatch = useAppDispatch();

  const [hash, setHash] = useState<string | undefined>();

  useEffect(() => {
    if (!hash) {
      fetchHash()
        .then((hash) => {
          setHash(hash);
        })
        .catch((error) => {
          logger.error(error);
        });
    }
  }, [hash]);

  useEffect(() => {
    if (hash) {
      dispatch(
        FeatureFlagActions.initialize({
          launchDarklyHash: hash,
        }),
      );
    }
  }, [hash]);
  return <></>;
};
export default Initializer;
