import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '../../app/store';
import { Flag } from '../../../../../common/featureflags/DingDongFlagConfigs';

export interface FlagState {
  key: string;
  value: boolean;
}

const adapter = createEntityAdapter<FlagState>({
  selectId: (flag) => flag.key,
});

export interface FeatureFlagContext {
  launchDarklyHash: string;
}

const slice = createSlice({
  name: 'featureFlags',
  initialState: {
    context: undefined as FeatureFlagContext | undefined,
    flags: adapter.getInitialState(),
  },
  reducers: {
    initialize: (state, action: PayloadAction<FeatureFlagContext>) => {
      state.context = action.payload;
    },
    reset: (state) => {
      state.context = undefined;
      adapter.removeAll(state.flags);
    },
    updateFlags: (state, action: PayloadAction<FlagState[]>) => {
      adapter.upsertMany(state.flags, action.payload);
    },
    syncFlags: (_) => {},
  },
});

export const FeatureFlagActions = slice.actions;

const adapterSelectors = adapter.getSelectors(
  (state: RootState) => state.featureFlags.flags,
);
export const FeatureFlagSelectors = {
  selectFlag: memoize(
    (flag: Flag) => (state: RootState) =>
      adapterSelectors.selectById(state, flag.key)?.value,
  ),
  selectContext: (state: RootState) => state.featureFlags.context,
};

export default slice.reducer;
