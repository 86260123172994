import React from 'react';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';

const FooterContainer = styled.div`
padding-left: 5%;
padding-right: 5%;
background-color: #000000;
width: 100%;

@media ${screens.medium} {
    padding-left: 44px;
    padding-right: 44px;
}
`

const FooterLogo = styled.img`
width: 74px;
display: block;
padding-top: 40px;
padding-bottom: 80px;
`

const FooterLinksList = styled.ul`
width: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: column;
list-style: none;
padding: 0;
height: 130px;

@media ${screens.medium} {
    width: 500px;
}  
`

const FooterListItem = styled.li`
width: 50%;
margin-bottom: 11px;
list-style: none;

@media ${screens.medium} {
    width: 250px;
}
`

const FooterLink = styled.a`
color: #ffffff;
font-size: 18px;
letter-spacing: 1px;
text-decoration: none;
`

const Divider = styled.div`
border: 1px solid #808080;
margin-bottom: 40px;
`

const Copyright = styled.div`
font-size: 14px;
letter-spacing: 1px;
color: #808080;
`

const Legal = styled.ul`
display: flex;
flex-wrap: wrap;
list-style: none;
padding: 0;
padding-top: 20px;
padding-bottom: 80px;
`

const LegalListItem = styled.li`
flex: 0 0 100%;
text-align: center;
margin-bottom: 10px;

@media ${screens.medium} {
    flex: unset;
    text-align: left;
    margin-bottom: 0px;
}
`

const LegalLink = styled.a`
color: #808080;
font-size: 16px;
text-decoration: none;
margin-right: 40px;
`

const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <FooterLogo src="https://www.wonder.inc/wonder-logo-white.png" />
            <FooterLinksList>
                <FooterListItem><FooterLink href="https://www.wonder.inc/about-us">About Us</FooterLink></FooterListItem>
                <FooterListItem><FooterLink href="https://accounts.wonder.inc">Account</FooterLink></FooterListItem>
                <FooterListItem><FooterLink href="https://developer.wonder.inc">Developers</FooterLink></FooterListItem>
                <FooterListItem><FooterLink href="https://www.wonder.inc/roam">Roam</FooterLink></FooterListItem>
                <FooterListItem><FooterLink href="https://www.wonder.inc/dynascore">Dynascore</FooterLink></FooterListItem>
            </FooterLinksList>
            <Divider />
            <Copyright>©2021, Wonder Inc. All rights reserved.</Copyright>
            <Legal>
                <LegalListItem><LegalLink href="https://www.wonder.inc/terms">Terms of Service</LegalLink></LegalListItem>
                <LegalListItem><LegalLink href="https://www.wonder.inc/privacy-policy">Privacy Policy</LegalLink></LegalListItem>
            </Legal>
        </FooterContainer>
    )
}

export default Footer;