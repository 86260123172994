import { styled } from '../styles/theme';

/**
 * Highlights a text span, emphasizing it.
 */
const Highlight = styled.span`
  border-color: black;
  border-style: solid;
  border-width: 0.15em;
  border-radius: 1em;

  padding: 0.2em 0.4em;
`;
export default Highlight;
