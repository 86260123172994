import React from 'react';
import { screens } from '../styles/breakpoints';
import { styled } from '../styles/theme';

interface Props {
  maxWidth?: string;
  children?: React.ReactNode;
}

const OuterSection = styled.div`
  width: 100%;

  @media ${screens.medium} {
    background-color: #02eb51;
    padding: 60px;
  }
`;

const Content = styled.div<Props>`
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${screens.medium} {
    max-width: ${(props) => props.maxWidth};
    margin-left: auto;
    margin-right: auto;
    border: 5px solid #000000;
  }
`;

Content.defaultProps = {
  maxWidth: '1440px',
};

const MainContent: React.FC<Props> = ({ children, maxWidth }) => {
  return (
    <OuterSection>
      <Content maxWidth={maxWidth}>{children}</Content>
    </OuterSection>
  );
};

export default MainContent;
