const minWidth = (size) => {
  return `(min-width: ${size})`;
};

// Screen size breakpoints.
const size = {
  small: '640px',
  medium: '768px',
};

export const screens = {
  small: minWidth(size.small),
  medium: minWidth(size.medium),
};
