import React, { useEffect, useState } from 'react';
import RequireWallet from '../../features/crypto/RequireWallet';
import {
  onMetaMaskAccountChange,
  provider,
  signer,
} from '../../crypto/metamask';
import verificationApi from '../../services/verificationApi';
import { logger } from '../../../../shared/infra/logger';
import { styled } from '../../styles/theme';
import MainContent from '../../components/MainContent';
import DescriptionParagraph from '../../features/collectibles/components/DescriptionParagraph';
import PageTitle from '../../components/PageTitle';
import Principal from '../../../../shared/features/verification/Principal';
import { useHistory } from 'react-router-dom';

const StyledButton = styled.button`
  background-color: #02eb46;
  border-radius: 5px;
  border: 1px solid #02eb46;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
`;

const DescriptionParagraphSpaced = styled(DescriptionParagraph)`
  margin-top: 25px;
`;

const Error = styled(DescriptionParagraph)`
  margin-top: 25px;
  color: red;
`;

const VerifyAddressScreen: React.FC = () => {
  const [address, setAddress] = useState<string>();
  const [signedMessage, setSignedMessage] = useState<string>();

  useEffect(() => {
    if (!provider) return;

    // First load.
    provider
      .listAccounts()
      .then((accounts) => setAddress(accounts[0]))
      .catch((error) => logger.error(error));

    // Event-based updates.
    onMetaMaskAccountChange(() => {
      provider
        .listAccounts()
        .then((accounts) => setAddress(accounts[0]))
        .catch((error) => logger.error(error));
    });
  }, [provider]);

  const [requestEthereumMessage, { data: message }] =
    verificationApi.useRequestEthereumMessageMutation();
  const [
    verifyEthereumMessage,
    { data: verifyResponse, isError: isVerifyError },
  ] = verificationApi.useVerifyCodeMutation();

  const history = useHistory();
  if (verifyResponse?.success) {
    // Just finished a successful verification.
    history.goBack();
  }

  const principal: Principal = {
    principalType: 'ETHEREUM_ADDRESS',
    principalValue: address,
  };

  const verifyAddress = async () => {
    void requestEthereumMessage(principal);
  };

  useEffect(() => {
    if (!signer) {
      // TODO: Error message if no signer?
      return;
    }
    // No message yet.
    if (!message) {
      return;
    }
    signer
      .signMessage(message)
      .then((signed) => setSignedMessage(signed))
      .catch((error) => {
        // User probably declined to sign, may not need an error message.
        logger.error(error);
      });
  }, [signer, message]);

  useEffect(() => {
    // Message not signed yet.
    if (!signedMessage) {
      return;
    }

    void verifyEthereumMessage({
      principal,
      code: signedMessage,
    });
  }, [address, signedMessage]);

  return (
    <MainContent>
      <RequireWallet>
        <PageTitle title="Verify Ethereum Address" />
        {isVerifyError && (
          <Error>Verification failed. Please refresh and try again!</Error>
        )}
        <DescriptionParagraphSpaced>
          Your Ethereum address:
          <br />
          {address}
        </DescriptionParagraphSpaced>
        <DescriptionParagraph>
          <StyledButton onClick={verifyAddress}>Verify My Address</StyledButton>
        </DescriptionParagraph>
        <DescriptionParagraph>
          This button will ask you to sign a message that proves that you own
          the Ethereum address listed above. This is completely free and
          off-chain.
        </DescriptionParagraph>
        <DescriptionParagraph>
          If the address above looks incorrect, please check the account you're
          connected as in Metamask.
        </DescriptionParagraph>
      </RequireWallet>
    </MainContent>
  );
};
export default VerifyAddressScreen;
