import React from 'react';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';

const HeadingText = styled.div`
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 40px;
  width: 100%;

  @media ${screens.medium} {
    font-size: 60px;
  }
`;

export default HeadingText;
