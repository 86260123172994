import React, { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';
import { MOBILE_SIDE_MARGIN } from '../styles/layout';

// TODO: height/width
// TODO: onpress UI
/**
 * A react-router link styled as a button.
 */
const StyledLink = styled(Link)`
  width: 95%;
  height: 64px;

  @media ${screens.small} {
    width: 350px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #02EB51;

  text-decoration: none;
  color: black;

  border-radius: 3px;

  font-size: 24px;
  margin-bottom: 15px;

  @media ${screens.medium} {
    margin-bottom: 80px;
    font-size: 36px;
  }
`;

const DisabledStyledLink = styled(StyledLink)`
  pointer-events: none;

  background-color: gray;
`;

type Props = {
  disabled?: boolean;
} & ComponentProps<typeof Link>;

const HOCLink: React.FC<Props> = (props) => {
  const { disabled, ...linkProps } = props;

  return disabled ? (
    <DisabledStyledLink onClick={() => {}} {...linkProps}></DisabledStyledLink>
  ) : (
    <StyledLink {...linkProps}></StyledLink>
  );
};
export default HOCLink;
