import { styled } from '../../../styles/theme';

const AttributeContainer = styled.div`
display: ${props => props.hidden ? 'none' : 'flex' };
flex-wrap: wrap;
width: 100%;
max-width: 1025px;
justify-content: center;
gap: 5px;
`;

export default AttributeContainer;
