import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PhraseScreen from '../screens/generate/PhraseScreen';
import RecordScreen from '../screens/generate/RecordScreen';
import RegisterScreen from '../screens/generate/RegisterScreen';
import StylesScreen from '../screens/generate/StylesScreen';
import routes from './routes';
import CenterColumnContainer from '../components/CenterColumnContainer';
import Header from '../components/Header';
import SubNav from '../components/SubNav';
import Footer from '../components/Footer';
import LogoSection from '../components/LogoSection';
import MainContent from '../components/MainContent';

const GenerateNavigator: React.FC = () => {
  let { path, url } = useRouteMatch();

  const mainContent = (
    <Switch>
      <Route exact path={routes.generate.root}>
        <Redirect to={routes.generate.phrase} />
      </Route>
      <Route path={routes.generate.phrase}>
        <PhraseScreen />
      </Route>
      <Route path={routes.generate.record}>
        <RecordScreen />
      </Route>
      <Route path={routes.generate.styles}>
        <StylesScreen />
      </Route>
      <Route path={routes.generate.register}>
        <RegisterScreen />
      </Route>
      <Route path={`${path}/*`}>{/*TODO: 404 page.*/}</Route>
    </Switch>
  );

  return (
    <CenterColumnContainer>
      <Header />
      <SubNav />
      <LogoSection />
      <MainContent children={mainContent} maxWidth="1000px" />
      <Footer />
    </CenterColumnContainer>
  );
};
export default GenerateNavigator;
