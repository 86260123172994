import { createSlice } from '@reduxjs/toolkit';
import VerifiedPrincipals from '../../../../shared/features/verification/VerifiedPrincipals';

interface VerificationState {
  principals: VerifiedPrincipals;
}

const initialState: VerificationState = {
  principals: {
    principals: [],
  },
};

export const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {},
});

export const VerificationAction = verificationSlice.actions;

export default verificationSlice.reducer;
