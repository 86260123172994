import axios from 'axios';
import { BASE_DOMAIN } from '../../services/wonderConfig';
import { logger } from '../../../../shared/infra/logger';

// Service for triggering generate dingdong actions.
// NOTE: Closely related to the RTK generate API, organization could be better.

// Returns the audio blob at the specified URI.
const fetchWebAudio = async (uri: string) => {
  const resource = await fetch(uri);
  return await resource.blob();
};

type UploadRecordingResponse = {
  recordingId: number;
};

/**
 * Sends an API request to upload a recording.
 */
export const requestUploadRecording = async (
  phrase: string,
  recordingUri: string,
) => {
  const audioBlob = await fetchWebAudio(recordingUri);

  const data = new FormData();
  data.append('phrase', phrase);
  data.append('audio_data', audioBlob);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return await axios
    .post<UploadRecordingResponse>(
      `${BASE_DOMAIN}/api/generate/recordings/upload`,
      data,
      config,
    )
    .then((response) => response.data)
    .catch((error) => {
      logger.error(error);
      return { error };
    });
};

type GenerateMelodyResponse = {
  melodyId: number;
};

/**
 * Sends an API request to generate a melody for the given recording.
 */
export const requestGenerateMelody = async (recordingId: number) => {
  return await axios
    .post<GenerateMelodyResponse>(
      `${BASE_DOMAIN}/api/generate/recordings/${recordingId}/generateMelody`,
    )
    .then((response) => response.data)
    .catch((error) => {
      logger.error(error);
      return { error };
    });
};

export const requestSaveDingDong = async (
  melodyId: number,
  styleId: number,
) => {
  return await axios
    .post(`${BASE_DOMAIN}/api/generate/dingdongs/save`, {
      melodyId,
      styleId,
    })
    .then((response) => response.data)
    .catch((error) => {
      logger.error(error);
      return { error };
    });
};
