import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import store from './app/store';
import GenerateNavigator from './navigation/GenerateNavigator';
import HomeScreen from './screens/HomeScreen';
import routes from './navigation/routes';
import CollectiblesNavigator from './navigation/CollectiblesNavigator';
import Initializer from './Initializer';
import ScrollToTop from './components/ScrollToTop';
import ManagementNavigator from './navigation/ManagementNavigator';

const greeterAddress = '0x5fbdb2315678afecb367f032d93f642f64180aa3';

const App: React.FC = () => {
  // const [greeting, setGreetingValue] = useState();
  //
  // // TODO: Find types rather than using hack.
  // const ethereum = (window as any).ethereum;
  //
  // // request access to the user's MetaMask account
  // async function requestAccount() {
  //   await ethereum.request({ method: 'eth_requestAccounts' });
  // }
  //
  // // call the smart contract, read the current greeting value
  // async function fetchGreeting() {
  //   if (typeof ethereum !== 'undefined') {
  //     const provider = new ethers.providers.Web3Provider(ethereum);
  //     const contract = new ethers.Contract(
  //       greeterAddress,
  //       Greeter.abi,
  //       provider,
  //     );
  //     try {
  //       const data = await contract.greet();
  //       console.log('data: ', data);
  //     } catch (err) {
  //       console.log('Error: ', err);
  //     }
  //   }
  // }
  //
  // // call the smart contract, send an update
  // async function setGreeting() {
  //   if (!greeting) return;
  //   if (typeof ethereum !== 'undefined') {
  //     await requestAccount();
  //     const provider = new ethers.providers.Web3Provider(ethereum);
  //     const signer = provider.getSigner();
  //     const contract = new ethers.Contract(greeterAddress, Greeter.abi, signer);
  //     const transaction = await contract.setGreeting(greeting);
  //     await transaction.wait();
  //     fetchGreeting();
  //   }
  // }
  //
  // fetchGreeting();

  return (
    <Provider store={store}>
      <Initializer />
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path={'/'}>
            <HomeScreen />
          </Route>
          <Route path={routes.generate.root}>
            <GenerateNavigator />
          </Route>
          <Route path={routes.collectibles.root}>
            <CollectiblesNavigator />
          </Route>
          <Route path={routes.management.root}>
            <ManagementNavigator />
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};
export default App;
