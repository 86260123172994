import React from 'react';
import { screens } from '../styles/breakpoints';
import { styled } from '../styles/theme';
import { useParams, Link } from 'react-router-dom';
import routes from '../navigation/routes';

const logo = require('../../static/images/logo_full_text.svg').default;

const LogoContainer = styled.div`
  height: 58px;

  @media ${screens.small} {
    height: 203px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogoLink = styled(Link)`
  line-height: 0;
`;

const Logo = styled.img`
  height: 28px;

  @media ${screens.small} {
    height: 64px;
  }
`;

const LogoSection: React.FC = () => {
    return (
        <LogoContainer>
            <LogoLink to={routes.root}>
                <Logo src={logo} />
            </LogoLink>
        </LogoContainer>
    )
};

export default LogoSection;