import React from 'react';
import StepContainer from '../../features/generate/components/StepContainer';
import CenterColumnContainer from '../../components/CenterColumnContainer';
import Headline from '../../features/generate/components/Headline';
import { styled } from '../../styles/theme';
import { screens } from '../../styles/breakpoints';

const topImage =
  require('../../../static/images/generate_step4.png').default;

const TopImage = styled.img`
  width: 96px;
  margin: 10px auto;
  
  @media ${screens.medium} {
    width: 152px;
    margin: 15px auto;
  }
`

const RegisterScreen: React.FC = () => {
  return (
    <CenterColumnContainer>
      <StepContainer>
        <TopImage src={topImage} />
        <Headline>Congratulations! <br /> New DingDong, who dis?</Headline>
        <Headline>Register to download your new DingDong!</Headline>
      </StepContainer>
    </CenterColumnContainer>
  );
};
export default RegisterScreen;
