import React from 'react';
import Style from '../../../../../shared/features/dingdong/Style';
import StyleIcon from './StyleIcon';
import { styled } from '../../../styles/theme';

type Props = {
  styles: Style[];
  onSelect: (style: Style) => void;
};

const defaultProps: Props = {
  styles: [],
  onSelect: () => {},
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
`;

const StyleSelector: React.FC<Props> = ({ styles, onSelect }) => {
  return (
    <Container>
      {styles.map((s) => {
        return (
          <StyleIcon
            key={s.id}
            // TODO: Select icon type
            icon={s.icons.FULL_COLOR}
            onClick={() => onSelect(s)}
          />
        );
      })}
    </Container>
  );
};
StyleSelector.defaultProps = defaultProps;
export default StyleSelector;
