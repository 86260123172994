import axios from 'axios';
import {
  allEnvironments,
  Environment,
  environmentLaunchDarklyClientSideIds,
  environmentMatomoSiteIds,
  isDevMode,
  overrideEnvironment,
} from './environment';
import {
  accountsServiceLocations,
  crmServiceLocations,
  dingDongServiceLocations,
  dynascoreServiceLocations,
  listeningServiceLocations,
  mediaServiceLocations,
  roamBackendServiceLocations,
  roamServiceLocations,
} from './serviceLocations';

export const environment: Environment =
  overrideEnvironment && allEnvironments.includes(overrideEnvironment)
    ? overrideEnvironment
    : isDevMode
    ? 'local'
    : 'dev';

export const matomoSiteIdForEnv = environmentMatomoSiteIds[environment];

export const launchDarklyClientIdForEnv =
  environmentLaunchDarklyClientSideIds[environment];

export const baseDynascoreURL = dynascoreServiceLocations[environment];

export const dynascoreHttp = axios.create({
  baseURL: baseDynascoreURL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformResponse: [
    (data) => {
      // Don't attempt to parse an empty response body (e.g., OK with no body)
      if (typeof data === 'string' && data.length === 0) {
        return;
      }

      return JSON.parse(data, dateReviver);
    },
  ],
});

export const baseAccountsURL = accountsServiceLocations[environment];

export const dynascoreLoginURL = new URL(
  '/signupin?app=dynascore&mode=signin&dest=/login/token',
  baseAccountsURL,
).href;

export const baseListeningURL = listeningServiceLocations[environment];

export const listeningHttp = axios.create({
  baseURL: baseListeningURL,
  // TODO: security token
  headers: {
    'Content-Type': 'application/json',
  },
  transformResponse: [
    (data) => {
      return JSON.parse(data, dateReviver);
    },
  ],
});

export const baseMediaURL = mediaServiceLocations[environment];

export const mediaHttp = axios.create({
  baseURL: baseMediaURL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformResponse: [
    (data) => {
      return JSON.parse(data, dateReviver);
    },
  ],
});

// Allows accessing Roam Middle End from multiple ports locally
export const baseRoamURL =
  typeof window !== 'undefined' &&
  window?.location?.hostname === 'localhost' &&
  window?.location?.port !== '3002' // electron
    ? window.location.origin
    : roamServiceLocations[environment];

export const baseWebhooksURL =
  typeof process !== 'undefined'
    ? process?.env?.WEBHOOK_BASE_URL ?? baseRoamURL
    : baseRoamURL;

export const baseOauthURL =
  typeof process !== 'undefined'
    ? process.env.OAUTH_BASE_URL ?? baseRoamURL
    : baseRoamURL;

export const baseRoamBackendURL =
  typeof window !== 'undefined' && window?.location?.hostname === 'localhost'
    ? window.location.origin
    : roamBackendServiceLocations[environment];

export const baseRoamAssetsURL = roamServiceLocations[environment];

export const roamHttp = axios.create({
  baseURL: baseRoamURL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformResponse: [
    (data) => {
      return JSON.parse(data, dateReviver);
    },
  ],
});

export const baseDingDongURL =
  typeof window !== 'undefined' && window?.location?.hostname === 'localhost'
    ? window.location.origin
    : dingDongServiceLocations[environment];

export const baseCrmURL =
  typeof window !== 'undefined' && window?.location?.hostname === 'localhost'
    ? window.location.origin
    : crmServiceLocations[environment];

// From https://stackoverflow.com/a/3143231
const dateFormat =
  /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/;

export const dateReviver = (_: any, value: unknown) => {
  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
};
