import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Recording = {
  // The (internal) ID of an uploaded recording.
  id?: number;
  // The status of uploading the recording.
  status: 'NOT_STARTED' | 'PENDING' | 'SUCCESS' | 'FAILURE';
};

type Melody = {
  // The (internal) ID of a generated melody.
  id?: number;
  // The status of generating the melody.
  status: 'NOT_STARTED' | 'PENDING' | 'SUCCESS' | 'FAILURE';
};

// NOTE: State values may not be for the same generate attempt.
interface GenerateState {
  phrase: string;
  value: number;

  recording: Recording;
  melody: Melody;
}

const initialState: GenerateState = {
  phrase: '',
  value: 0,
  recording: {
    status: 'NOT_STARTED',
  },
  melody: {
    status: 'NOT_STARTED',
  },
};

// BEGIN PayloadAction Types

export interface UploadRecordingRequest {
  phrase: string;
  recordingUri: string;
}

export interface GenerateMelodyRequest {
  // Should be the same as the current state value.
  // The endpoint supports others, but triggers subsequent actions.
  recordingId: number;
}

export interface SaveDingDongRequest {
  melodyId: number;
  styleId: number;
}

// END PayloadAction Types

export const generateSlice = createSlice({
  name: 'generate',
  initialState,
  reducers: {
    reset: (state) => {
      state.phrase = '';
      state.recording = {
        id: undefined,
        status: 'NOT_STARTED',
      };
      state.melody = {
        id: undefined,
        status: 'NOT_STARTED',
      };
    },

    setPhrase: (state, action: PayloadAction<string>) => {
      state.phrase = action.payload;
    },

    uploadRecording: (
      state,
      action: PayloadAction<UploadRecordingRequest>,
    ) => {},
    uploadRecordingSuccess: (state, action: PayloadAction<number>) => {},
    uploadRecordingFailure: (state) => {},
    setRecording: (state, action: PayloadAction<Recording>) => {
      state.recording = action.payload;
    },

    generateMelody: (state, action: PayloadAction<GenerateMelodyRequest>) => {},
    generateMelodySuccess: (state, action: PayloadAction<number>) => {},
    generateMelodyFailure: (state) => {},
    setMelody: (state, action: PayloadAction<Melody>) => {
      state.melody = action.payload;
    },

    saveDingDong: (state, action: PayloadAction<SaveDingDongRequest>) => {},
  },
});

export const GenerateActions = generateSlice.actions;

export default generateSlice.reducer;
