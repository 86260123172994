import React, { useState }  from 'react';
import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';
import AttributeContainer from './AttributeContainer';

interface Props {
    type: string;
    attributeRows: any;
}

const TypeRow = styled.div`
cursor: pointer;
font-size: 20px;
font-weight: bold;
border-bottom: 1px solid black;
width: 70%;
padding-top: 20px;
margin-bottom: 10px;

@media ${screens.medium} {
    width: 60%;
    &:hover {
        border-bottom: 2px solid black;
    }
}
`;

const Expand = styled.span`
float: right;
margin-right: 20px;
`

const CollapsableAttributes: React.FC<Props> = ({ type, attributeRows }) => {
    const [ expanded, setExpanded ] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    return (
        <>
            <TypeRow onClick={() => toggleExpanded()}>
                {type}
                <Expand>{expanded ? '–' : '+'}</Expand>
            </TypeRow>
            <AttributeContainer hidden={!expanded}>
                {attributeRows}
            </AttributeContainer>
        </>
    )
}

export default CollapsableAttributes;