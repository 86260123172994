import React from 'react';
import { screens } from '../../styles/breakpoints';
import { styled } from '../../styles/theme';
import CenterColumnContainer from '../../components/CenterColumnContainer';
import SubNav from '../../components/SubNav';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LogoSection from '../../components/LogoSection';
import MainContent from '../../components/MainContent';
import HeadingText from '../../components/HeadingText';
import { useHistory } from 'react-router-dom';
import collectibleApi from '../../services/collectibleApi';
import routes from '../../navigation/routes';
import { reverse } from 'named-urls';

// const smileBanner =
//   require('../../../static/images/SmileLikeYouMintIt_Banner.png').default;

const FeaturedImage = styled.img`
  border: 3px solid #000000;
  margin: 15px 15px 95px 15px;
  width: calc(100% - 30px);

  @media ${screens.medium} {
    margin: 50px 58px 95px 58px;
    width: calc(100% - 116px);
  }
`;

const CollectionName = styled.div`
  width: 100%;
  font-size: 18px;
  margin: 15px;

  @media ${screens.medium} {
    margin: 0px 58px;
    font-size: 24px;
  }
`;

const CollectionImage = styled(FeaturedImage)`
  margin-top: 0px;
  cursor: pointer;
`;

const HeadingTextSpaced = styled(HeadingText)`
  margin-top: 25px;
`;

const CollectionDescription = styled.div`
  text-align: center;
  font-size: 18px;
  width: 100%;
  margin-bottom: 15px;

  @media ${screens.medium} {
    font-size: 24px;
    margin-bottom: 50px;
  }
`;

const Green = styled.span`
  color: #02eb51;
`;

const BrowseCollectionScreen: React.FC = () => {
  const history = useHistory();

  const goToCollection = (collectionId: number) => {
    const url = reverse(routes.collectibles.collection.detail.root, {
      collectionId,
    });
    history.push(url);
  };

  const {
    data: collections,
    isLoading: collectionsLoading,
    isSuccess: collectionsSuccess,
    isError: collectionsError,
  } = collectibleApi.useFetchAllCollectionsQuery();

  // Featured collections disabled until we want to actually show something.
  // const featuredCollectionContent = (
  //   <>
  //     <PageTitle title="Collectible" />
  //     <HeadingTextSpaced>Featured Collection</HeadingTextSpaced>
  //     <CollectionDescription>
  //       Smile Like You Mint It <br /> 50 Dingdongs
  //     </CollectionDescription>
  //     <CollectionImage src={smileBanner} onClick={() => goToCollection('5')} />
  //   </>
  // );

  let collectionComponents;
  if (collectionsLoading) {
    // Do we want to show something?
    collectionComponents = <></>;
  } else if (collectionsError) {
    collectionComponents = <>Oops, something went wrong!</>;
  } else if (collectionsSuccess) {
    collectionComponents = collections.map((collection) => (
      <React.Fragment key={collection.id}>
        <CollectionName>
          {collection.name} <Green>/ {collection.size} DingDongs</Green>
        </CollectionName>
        <CollectionImage
          src={collection.coverImageAbsoluteUrl}
          onClick={() => goToCollection(collection.id)}
        />
      </React.Fragment>
    ));
  } else {
    throw Error('Oops! Unexpected state.');
  }

  const allCollectionsContent = (
    <>
      <HeadingTextSpaced>All Collections</HeadingTextSpaced>
      {collectionComponents}
    </>
  );

  return (
    <CenterColumnContainer>
      <Header />
      <SubNav />
      <LogoSection />
      {/*<MainContent children={featuredCollectionContent} />*/}
      <MainContent children={allCollectionsContent} />
      <Footer />
    </CenterColumnContainer>
  );
};
export default BrowseCollectionScreen;
