import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_DOMAIN } from './wonderConfig';

type GeneratedDingDongRequest = {
  melodyId: number;
  styleId: number;
};

/**
 * API for fetching generated objects (primarily DingDongs).
 *
 * Closely related to generateSlice/generateSaga.
 * This is separate to takee advantage of RTK query.
 */
const generateApi = createApi({
  reducerPath: 'generateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_DOMAIN}/api/generate`,
  }),
  endpoints: (builder) => ({
    fetchGeneratedDingDong: builder.query<
      { status: 'SUCCESS' | 'FAILURE' },
      GeneratedDingDongRequest
    >({
      query: ({ melodyId, styleId }) => ({
        url: `/melodies/${melodyId}/dingdong/status?styleId=${styleId}`,
      }),
    }),
  }),
});
export default generateApi;
