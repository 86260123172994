import React from 'react';
import TypeCard from './TypeCard';
import routes from '../navigation/routes';
import { Link } from 'react-router-dom';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';
import { useAppSelector } from '../app/hooks';
import { FeatureFlagSelectors } from '../features/featureflags/featureFlagsSlice';
import { DINGDONG_ENABLE_PERSONAL } from '../../../../common/featureflags/DingDongFlagConfigs';

const Descriptor = styled.p`
  font-size: 24px;
  line-height: 40px;
  margin: 0 auto;
  margin-top: 84px;
  margin-bottom: 74px;
  width: 95%;
  text-align: center;

  @media ${screens.medium} {
    font-size: 36px;
    line-height: 56px;
    max-width: 890px;
    margin-top: 150px;
    margin-bottom: 136px;
  }
`;

const Circled = styled.span`
  border: 3px solid black;
  border-radius: 1em;
  padding: 0.025em 0.4em;
`;

const CircledGreen = styled(Circled)`
  border-color: #02eb51;
  color: #02eb51;
`;

const TypeCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  justify-content: center;

  @media ${screens.medium} {
    width: 100vw;
  }
`;

const Header1 = styled.h1`
  font-size: 36px;
  margin-top: 73px;
  text-transform: uppercase;
  text-align: center;

  @media ${screens.medium} {
    font-size: 60px;
    margin-top: 150px;
  }
`;

const Header2 = styled.h2`
  font-size: 19px;
  line-height: 29px;
  letter-spacing: 1px;
  text-align: center;
  max-width: 780px;
  width: 95%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 100px;

  @media ${screens.medium} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const HomepageMain: React.FC = () => {
  const enablePersonalFlag = useAppSelector(
    FeatureFlagSelectors.selectFlag(DINGDONG_ENABLE_PERSONAL),
  );

  const personalTypeImage = require('../../static/images/personal_type.png');
  const collectibleTypeImage = require('../../static/images/collectible_type.png');

  return (
    <div>
      <Descriptor>
        A <CircledGreen>Dingdong</CircledGreen> is your own signature
        <Circled>walk-on music.</Circled> <br />
        Make your <Circled>entrance</Circled> with <Circled>style</Circled> all
        over the metaverse and normalverse.
      </Descriptor>
      <TypeCardsContainer>
        {enablePersonalFlag && (
          <TypeCard
            link={routes.generate.root}
            background="#02eb51"
            text="Free and just takes a minute"
            image="/static/personal_type.png"
          />
        )}
        <TypeCard
          link={routes.collectibles.root}
          background="#000000"
          text="Limited editions from world-famous artists, traded on the blockchain as NFTs"
          image="/static/collectible_type.png"
        />
      </TypeCardsContainer>
      <Header1>Using Your DingDong</Header1>
      <Header2>
      Once you have your DingDong audio file, you can use it anywhere: add it to your Instagram or TikTok stories, play it when you join a Zoom call, or when you take the stage in a Discord server.
      </Header2>
    </div>
  );
};

export default HomepageMain;
