import React from 'react';
import Collection from '../../../../../shared/features/collectibles/Collection';
import Nft from '../../../../../shared/features/collectibles/Nft';
import { skipToken } from '@reduxjs/toolkit/query';
import cryptoApi from '../../../services/cryptoApi';
import SpacedHeadingText from '../../../components/SpacedHeadingText';
import DescriptionParagraph from './DescriptionParagraph';
import { signer } from '../../../crypto/metamask';
import { ethers } from 'ethers';
import DingDongCollection from '../../../artifacts/contracts/DingDongCollection.sol/DingDongCollection.json';
import { logger } from '../../../../../shared/infra/logger';
import RequireWallet from '../../crypto/RequireWallet';

interface Props {
  collection: Collection;
  nft: Nft;
}

/**
 * Displays ownership status of an NFT, as well as any applicable actions that the user can take.
 */
const NftStatusActions: React.FC<Props> = ({ collection, nft }) => {
  // TODO: Better handling of loading.
  if (!collection || !nft) {
    return <></>;
  }

  const { mintable, marketable } = collection;

  // TODO: Ideally this component is pure.
  const nftRequest =
    collection && nft?.minted
      ? { contractAddress: collection?.contractAddress, tokenId: nft.tokenId }
      : skipToken;
  const { data: nftOwner } = cryptoApi.useFetchTokenOwnerQuery(nftRequest);

  // TODO: Move to cryptoApi.
  const claim = async () => {
    if (!signer) return;

    const contract = new ethers.Contract(
      collection.contractAddress,
      DingDongCollection.abi,
      signer,
    );
    const txn = await contract
      .redeem(JSON.parse(nft.voucher))
      .catch((error) => {
        logger.error(error);
        throw Error(error);
      });
    await txn.wait().catch((error) => {
      logger.error(error);
      throw Error(error);
    });
  };

  let ownership;
  const actions = [];
  if (nft.minted) {
    // If minted, it definitely has an owner.
    ownership = (
      <DescriptionParagraph>
        This DingDong is currently owned by {nftOwner?.substring(0, 10)}.
      </DescriptionParagraph>
    );

    if (marketable) {
      // TODO: Add marketplace actions.
    }
  } else if (mintable) {
    ownership = (
      <DescriptionParagraph>
        This DingDong hasn't been minted yet. Claim it now!
      </DescriptionParagraph>
    );
    actions.push(
      <button
        onClick={() => {
          claim().catch((error) => logger.error(error));
        }}
      >
        Claim
      </button>,
    );
  } else {
    // Not minted and not mintable.
    // Typically things are only in this state when we're deploying a new collection.
    ownership = (
      <DescriptionParagraph>
        This DingDong will be minted as part of an exclusive release.
      </DescriptionParagraph>
    );
  }

  return (
    <>
      <SpacedHeadingText>Ownership</SpacedHeadingText>
      {ownership}
      {actions.length > 0 && (
        <DescriptionParagraph>
          <RequireWallet>{actions}</RequireWallet>
        </DescriptionParagraph>
      )}
    </>
  );
};
export default NftStatusActions;
