import React, { useEffect, useState } from 'react';
import { screens } from '../../styles/breakpoints';
import { styled } from '../../styles/theme';
import CenterColumnContainer from '../../components/CenterColumnContainer';
import SubNav from '../../components/SubNav';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LogoSection from '../../components/LogoSection';
import { useParams } from 'react-router-dom';
import collectibleApi from '../../services/collectibleApi';
import DingDongRowItem from '../../features/collectibles/components/DingDongRowItem';
import MainContent from '../../components/MainContent';
import PageTitle from '../../components/PageTitle';
import HeadingText from '../../components/HeadingText';
import CollapsableAttributes from '../../features/collectibles/components/CollapsableAttributes';
import Attribute from '../../features/collectibles/components/Attribute';
import { provider, signer } from '../../crypto/metamask';
import { ethers } from 'ethers';
import Greeter from '../../artifacts/contracts/Greeter.sol/Greeter.json';
import routes from '../../navigation/routes';
import { reverse } from 'named-urls';

const HeroImage = styled.img`
  border: 3px solid #000000;
  margin: 15px 15px 95px 15px;
  width: calc(100% - 30px);

  @media ${screens.medium} {
    margin: 50px 58px 95px 58px;
    width: calc(100% - 116px);
  }
`;

const NftList = styled.div`
  width: 100%;
  margin-top: 40px;

  @media ${screens.medium} {
    margin-top: 104px;
  }
`;

const CollectionDescription = styled.div`
  text-align: center;
  font-size: 13px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: -30px;

  @media ${screens.medium} {
    font-size: 18px;
  }
`;

/**
 * Screen to view the contents of a collection.
 */
const CollectionScreen: React.FC = () => {
  const [providerContract, setProviderContract] = useState<ethers.Contract>();
  const [signerContract, setSignerContract] = useState<ethers.Contract>();

  const { collectionId: unsafeCollectionId } =
    useParams<{ collectionId: string }>();

  const collectionId = parseInt(unsafeCollectionId);

  // TODO: is loading, error handling
  const { data: collection } =
    collectibleApi.useFetchCollectionQuery(collectionId);
  const { data: nfts, isLoading: isNftsLoading } =
    collectibleApi.useFetchCollectionNftsQuery(collectionId);

  const nftItems = isNftsLoading
    ? []
    : nfts.map((nft) => {
        const url = reverse(routes.collectibles.collection.detail.nfts.detail, {
          collectionId: nft.collectionId,
          nftTokenId: nft.tokenId,
        });
        return (
          <DingDongRowItem key={nft.id} dingDong={nft.dingDong} url={url} />
        );
      });

  useEffect(() => {
    if (!provider) return;

    const address = collection?.contractAddress;
    if (!address) return;

    setProviderContract(new ethers.Contract(address, Greeter.abi, provider));
  }, [collection, provider]);

  useEffect(() => {
    if (!signer) return;

    const address = collection?.contractAddress;
    if (!address) return;

    setSignerContract(new ethers.Contract(address, Greeter.abi, signer));
  }, [collection, signer]);

  const formatAttributeDetails = (attr) => {
    const { attribute, count } = attr;
    const attributeData = {
      name: attribute.type.name,
      value: attribute.value,
      count: count + ' (' + Math.trunc((count / collection.size) * 100) + '%)',
    };

    return <Attribute attribute={attributeData} />;
  };

  const attributeTypes = Array.from(
    new Set(
      collection?.attributes.map((attr: any) => attr.attribute.type.name),
    ),
  );

  const attributeDetails = attributeTypes.map((type) => {
    const attributesForType = collection?.attributes.filter(
      (attr) => attr.attribute.type.name === type,
    );
    return (
      <CollapsableAttributes
        type={type}
        attributeRows={attributesForType.map((attr) =>
          formatAttributeDetails(attr),
        )}
      />
    );
  });

  const mainContent = (
    <>
      <PageTitle title="Collectible" />
      <HeroImage src={collection?.coverImageAbsoluteUrl} />
      <HeadingText>{collection?.name}</HeadingText>
      <CollectionDescription>
        {collection?.curator}
        <br />
        {`Collection of ${collection?.size} DingDongs`}
        <br />
        {collection?.description}
      </CollectionDescription>
      <HeadingText>Attributes</HeadingText>
      {attributeDetails}
      <NftList>{nftItems}</NftList>
    </>
  );

  // async function emitA() {
  //   if (!signerContract) return;
  //   const transaction = await signerContract.emitA();
  //   await transaction.wait().then(() => {
  //     logger.info('a done');
  //   });
  // }
  // async function emitB() {
  //   if (!signerContract) return;
  //
  //   const transaction = await signerContract.emitB();
  //   await transaction.wait().then(() => {
  //     logger.info('b done');
  //   });
  // }

  // if (providerContract) {
  //   const aFilter = providerContract.filters.EventA();
  //   providerContract.on(aFilter, (e) => {
  //     logger.info('trigger A', e);
  //   });
  //
  //   const filter2 = {
  //     ...aFilter,
  //     fromBlock: 'earliest',
  //   };
  //
  //   provider.getLogs(filter2).then((events) => {
  //     logger.info('all logs', events);
  //   });
  // }

  return (
    <CenterColumnContainer>
      <Header />
      <SubNav />
      <LogoSection />
      <MainContent children={mainContent} />
      <Footer />
    </CenterColumnContainer>
  );
};
export default CollectionScreen;
