import { ethers } from 'ethers';
import { ethereumNetwork } from './ethereumNetwork';
import { environment } from '../../../../common/api/http';

// TODO/NOTE:
// This provider is currently accessed via web/client, which means our API keys are getting exposed.
// This is temporarily okay (assuming it doesn't impact pricing/security), but it is debt we need to fix.
// The approach is that web requests should be proxied and done on the backend.
// As these keys just provide access to providers, it should be okay to rotate/swap them.
// To confirm that things are secure, this file should be moved to backend and confirmed to not be used by shared/client code.

let ALCHEMY_API_KEY: string;
let ETHERSCAN_API_KEY: string;
let INFURA_PROJECT_ID: string;
let POKT_PORTAL_ID: string;

switch (environment) {
  case 'local':
    ALCHEMY_API_KEY = 'UNSUPPORTED';
    ETHERSCAN_API_KEY = 'UNSUPPORTED';
    INFURA_PROJECT_ID = 'UNSUPPORTED';
    POKT_PORTAL_ID = 'UNSUPPORTED';
    break;
  case 'dev':
  case 'stage':
  case 'exp':
    // In Vincent's Alchemy account. Nothing special; safe to rotate.
    // Configured to ropsten.
    ALCHEMY_API_KEY = 'UtSKrhvAZbfWpltBm_TLMy7N7i873NxO';
    // Vincent's Etherscan account (vplewonder), free plan. Safe to rotate.
    // Etherscan keys are usable with mainnet & ropsten. This is the same key as prod.
    ETHERSCAN_API_KEY = 'FIC8I6HS18RB8SN5UD1Z5CAYNZEB5E7JNT';
    // Vincent's Infura account (vplewonder), free plan. Safe to rotate.
    // Infura projects are usable with mainnet & ropsten. This is the same key as prod.
    INFURA_PROJECT_ID = '733afe9be8394e1e968310faeb6e7513';
    // Vincent's Pokt account. Safe to rotate. Ropsten.
    POKT_PORTAL_ID = '61d5f4f21a94ee003a8e1eda';
    break;
  case 'prod':
    // https://dashboard.alchemyapi.io/apps/h0lsj3ak9des155m
    // In Vincent's Alchemy account. Nothing special; safe to rotate.
    // Configured to mainnet.
    ALCHEMY_API_KEY = 'DVDPi8xkxUiV1ihOPEu4jKJhL7fdz4Z0';
    // Vincent's Etherscan account (vplewonder), free plan. Safe to rotate.
    // Etherscan keys are usable with mainnet & ropsten. This is the same key as dev/stage/exp.
    ETHERSCAN_API_KEY = 'FIC8I6HS18RB8SN5UD1Z5CAYNZEB5E7JNT';
    // Vincent's Infura account (vplewonder), free plan. Safe to rotate.
    // Infura projects are usable with mainnet & ropsten. This is the same key as dev/stage/exp.
    INFURA_PROJECT_ID = '733afe9be8394e1e968310faeb6e7513';
    // Vincent's Pokt account. Safe to rotate. Mainnet.
    POKT_PORTAL_ID = '61d5f4341a94ee003a8e18f9';
    break;
  default:
    throw new Error('Invalid environment!');
}

// TODO: Configure wonder service apis.
/**
 * Provider to access Ethereum.
 *
 * This is a general-purpose provider, meaning that it does not have any privileged account access.
 * It cannot act on behalf of an account, be it a Wonder account or a user account.
 */
const options: any =
  environment === 'local'
    ? {}
    : {
        alchemy: ALCHEMY_API_KEY,
        etherscan: ETHERSCAN_API_KEY,
        infura: INFURA_PROJECT_ID,
        pocket: POKT_PORTAL_ID,
      };
const ethereumProvider = ethers.getDefaultProvider(
  ethereumNetwork.networkish,
  options,
);

export default ethereumProvider;
