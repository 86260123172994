import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { GenerateActions } from '../../features/generate/generateSlice';
import StepContainer from '../../features/generate/components/StepContainer';
import routes from '../../navigation/routes';
import LinkButton from '../../components/LinkButton';
import PageTitle from '../../components/PageTitle';
import { styled } from '../../styles/theme';
import { screens } from '../../styles/breakpoints';
import PhraseInput from '../../features/generate/components/PhraseInput';
import Headline from '../../features/generate/components/Headline';
import Highlight from '../../components/Highlight';

const topImage =
  require('../../../static/images/generate_step1.png').default;

const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Rename...
const Footnote = styled.div`
  margin: 1em 0;

  text-align: center;

  color: #c4c4c4;
  font-size: 13px;

  margin-top: 50px;
  margin-bottom: 98px;

  @media ${screens.medium} {
    font-size: 14px;
    letter-spacing: 1px;
    margin-top: 75px;
    margin-bottom: 70px;
  }
`;

const TopImage = styled.img`
width: 96px;
margin: 10px auto;

@media ${screens.medium} {
  width: 152px;
  margin: 15px auto;
}
`

const PhraseScreen: React.FC = () => {
  const [phrase, setPhrase] = useState<string>('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GenerateActions.reset());
  }, []);

  // Must be a multi-syllable phrase.
  const disableContinue = phrase.trim().indexOf(' ') === -1;

  const continueAction = () => {
    dispatch(GenerateActions.setPhrase(phrase));
  };

  return (
    <>
      <PageTitle title="Personal" />
      <StepContainer title={''}>
        <TopImage src={topImage} />
        <Headline>
          <p>
            Enter your <Highlight>desired phrase</Highlight> for your DingDong
            below...
          </p>
        </Headline>
        <Content>
          <PhraseInput phrase={phrase} onChange={setPhrase} />
        </Content>
        <Footnote>
          <p>Example: "I'm King Kong, hear me roar!"</p>
        </Footnote>
      </StepContainer>
      <LinkButton
          to={routes.generate.record}
          onClick={continueAction}
          disabled={disableContinue}
        >
          Continue
      </LinkButton>
    </>
  );
};

export default PhraseScreen;
