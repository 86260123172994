import React, { useRef } from 'react';
import { screens } from '../../styles/breakpoints';
import { styled } from '../../styles/theme';
import collectibleApi from '../../services/collectibleApi';
import { useParams } from 'react-router-dom';
import CenterColumnContainer from '../../components/CenterColumnContainer';
import SubNav from '../../components/SubNav';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LogoSection from '../../components/LogoSection';
import MainContent from '../../components/MainContent';
import PageTitle from '../../components/PageTitle';
import HeadingText from '../../components/HeadingText';
import DescriptionParagraph from '../../features/collectibles/components/DescriptionParagraph';
import AttributeContainer from '../../features/collectibles/components/AttributeContainer';
import Attribute from '../../features/collectibles/components/Attribute';
import PlayButton from '../../features/collectibles/components/PlayButton';
import cryptoApi from '../../services/cryptoApi';
import { skipToken } from '@reduxjs/toolkit/query';
import RequireWallet from '../../features/crypto/RequireWallet';
import TransactionHistoryTable from '../../features/collectibles/components/TransactionHistoryTable';
import { signer } from '../../crypto/metamask';
import { ethers } from 'ethers';
import DingDongCollection from '../../artifacts/contracts/DingDongCollection.sol/DingDongCollection.json';
import { logger } from '../../../../shared/infra/logger';
import SpacedHeadingText from '../../components/SpacedHeadingText';
import NftStatusActions from '../../features/collectibles/components/NftStatusActions';

const playButton =
  require('../../../static/images/play_button_green.png').default;

const CenterDiv = styled.div`
  width: 100%;
  text-align: center;
`;

const LightGray = styled.span`
  @media ${screens.medium} {
    color: #c4c4c4;
  }
`;

const DingdongImage = styled.img`
  border: 3px solid black;
  width: 330px;
  margin-top: 60px;
  margin-bottom: 25px;

  @media ${screens.medium} {
    width: 363px;
  }
`;

const DingdongVideo = styled.video`
  border: 3px solid black;
  width: 330px;
  margin-top: 60px;
  margin-bottom: 25px;

  @media ${screens.medium} {
    width: 363px;
  }
`;

const HeadingTextSpaced = styled(HeadingText)`
  margin-top: 25px;
`;

interface Props {}

// TODO: Built out.
const NftDetailScreen: React.FC<Props> = ({}) => {
  const { collectionId: unsafeCollectionId, nftTokenId: unsafeTokenId } =
    useParams<{ collectionId: string; nftTokenId: string }>();

  const collectionId = parseInt(unsafeCollectionId);
  const tokenId = parseInt(unsafeTokenId);

  const { data: wonderNft } = collectibleApi.useFetchCollectionNftQuery({
    collectionId,
    tokenId,
  });
  const { data: collection } = collectibleApi.useFetchCollectionQuery(
    wonderNft?.collectionId,
  );
  const dingdong = wonderNft?.dingDong;

  const attributeDetails = wonderNft?.attributes.map((attribute) => {
    const attributeData = {
      name: attribute.type.name,
      value: attribute.value,
    };
    const key = `${attribute.type.name}-${attribute.value}`;
    return <Attribute key={key} attribute={attributeData} />;
  });

  // TODO: Probably good to combine these.
  const nftRequest =
    collection && wonderNft?.minted
      ? { contractAddress: collection?.contractAddress, tokenId }
      : skipToken;

  // TODO: All history items, not just transfers.
  const { data: tokenTransferHistory } =
    cryptoApi.useFetchTokenTransferHistoryQuery(nftRequest);

  // TODO: Move to cryptoApi.
  const claim = async () => {
    if (!signer) return;

    const contract = new ethers.Contract(
      collection.contractAddress,
      DingDongCollection.abi,
      signer,
    );
    const txn = await contract
      .redeem(JSON.parse(wonderNft.voucher))
      .catch((error) => {
        logger.error(error);
        throw Error(error);
      });
    await txn.wait().catch((error) => {
      logger.error(error);
      throw Error(error);
    });
  };

  const actions = wonderNft?.minted ? (
    <></>
  ) : (
    <>
      <button
        onClick={() => {
          claim();
        }}
      >
        Claim
      </button>
    </>
  );

  const vidRef = useRef(null);
  const playVideo = () => {
    dingdong?.animationAbsoluteUrl && vidRef.current.play();
  };

  // Assumes that there will always be at least one of animation/image.
  const coverMedia = dingdong ? (
    dingdong.animationAbsoluteUrl ? (
      <DingdongVideo
        ref={vidRef}
        muted
        playsInline
        src={dingdong.animationAbsoluteUrl}
      />
    ) : (
      <DingdongImage src={dingdong.imageAbsoluteUrl} />
    )
  ) : (
    <></>
  );

  const mainContent = (
    <>
      <PageTitle title="Collectible" />
      {dingdong && <HeadingTextSpaced>{dingdong.name}</HeadingTextSpaced>}
      <CenterDiv>{coverMedia}</CenterDiv>
      {dingdong && (
        <PlayButton
          onCollectionScreen={false}
          dingDong={wonderNft.dingDong}
          src={playButton}
          onClickEvent={playVideo}
        />
      )}

      <NftStatusActions collection={collection} nft={wonderNft} />
      <HeadingText>Attributes</HeadingText>
      <AttributeContainer>{attributeDetails}</AttributeContainer>
      <SpacedHeadingText>Transaction History</SpacedHeadingText>
      {tokenTransferHistory && (
        <TransactionHistoryTable history={tokenTransferHistory} />
      )}
    </>
  );

  return (
    <CenterColumnContainer>
      <Header />
      <SubNav />
      <LogoSection />
      <MainContent children={mainContent} />
      <Footer />
    </CenterColumnContainer>
  );
};
export default NftDetailScreen;
