import { call, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, select } from 'redux-saga/effects';
import {
  FeatureFlagActions,
  FeatureFlagContext,
  FeatureFlagSelectors,
  FlagState,
} from './featureFlagsSlice';
import { DingDongFlags } from '../../../../../common/client/featureflags/dingdongflags';
import store from '../../app/store';
import { ALL_DINGDONG_FLAGS } from '../../../../../common/featureflags/DingDongFlagConfigs';
import { logger } from '../../../../shared/infra/logger';

export function* initialize(
  action: PayloadAction<FeatureFlagContext>,
): SagaIterator {
  const hash = action.payload.launchDarklyHash;
  const dingDongFlags = DingDongFlags.getInstance(hash);
  if (!dingDongFlags.isReady()) {
    yield call([dingDongFlags, dingDongFlags.waitForReady], () => {
      store.dispatch(FeatureFlagActions.syncFlags());
    });
  }

  yield put(FeatureFlagActions.syncFlags());
}

export function* syncFlags(): SagaIterator {
  const context: FeatureFlagContext = yield select(
    FeatureFlagSelectors.selectContext,
  );
  if (context) {
    const dingDongFlags = DingDongFlags.getInstance(context.launchDarklyHash);
    if (dingDongFlags?.isReady()) {
      let flags: FlagState[] = [];
      for (const flag of ALL_DINGDONG_FLAGS) {
        const result = yield call(
          [dingDongFlags, dingDongFlags.checkFlag],
          flag,
        );
        flags.push({
          key: flag.key,
          value: result,
        });
      }
      yield put(FeatureFlagActions.updateFlags(flags));
    }
    // Nothing to do if not ready. When it becomes ready, all the flags will get synced anyway.
  } else {
    logger.warn(`DingDong feature flags not initialized, can't sync flags`);
  }
}

export function* featureFlagsSaga(): SagaIterator {
  yield takeLatest(FeatureFlagActions.initialize, initialize);
  yield takeLatest(FeatureFlagActions.syncFlags, syncFlags);
}
