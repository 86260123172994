import React  from 'react';
import internal from 'stream';
import { styled } from '../../../styles/theme';

const AttributeRow = styled.div`
width: 250px;
border: 2px solid #02eb51;
border-radius: 10px;
padding: 10px;
background-color: rgba(2, 235, 81, .1);
text-align: center;
`;

const AttributeName = styled.div`
font-weight: bold;
text-transform: uppercase;
color: #02eb51;
font-size: 14px;
`;

const AttributeValue = styled.div`
font-size: 20px;
font-weight: bold;
`;

const AttributeCount = styled.div`
color: #c4c4c4;
`;

interface AttributeData {
    name: string;
    value: string;
    count?: string;
}

interface Props {
    attribute: AttributeData;
}

const Attribute: React.FC<Props> = ({ attribute }) => {
    return (
        <AttributeRow>
            <AttributeName>{attribute.name}</AttributeName>
            <AttributeValue>{attribute.value}</AttributeValue>
            <AttributeCount>{attribute.count ? attribute.count + " have this trait" : ""}</AttributeCount>
        </AttributeRow>
    )

};

export default Attribute;