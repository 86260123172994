import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_DOMAIN } from './wonderConfig';
import Collection from '../../../shared/features/collectibles/Collection';
import Nft from '../../../shared/features/collectibles/Nft';

type CollectionNftRequest = {
  collectionId: number,
  tokenId: number,
};

/**
 * Queries the Wonder collectibles API.
 */
const collectibleApi = createApi({
  reducerPath: 'collectibleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_DOMAIN}/api/collectibles`,
  }),
  endpoints: (builder) => ({
    fetchAllCollections: builder.query<Collection[], void>({
      query: () => ({
        url: '/collections/all',
      }),
    }),
    fetchCollection: builder.query<Collection, number>({
      query: (id) => ({
        url: `/collections/${id}`,
      }),
    }),
    fetchCollectionNfts: builder.query<Nft[], number>({
      query: (collectionId) => ({
        url: `/collections/${collectionId}/nfts`,
      }),
    }),
    fetchCollectionNft: builder.query<Nft, CollectionNftRequest>({
      query: ({ collectionId, tokenId }) => ({
        url: `/collections/${collectionId}/nfts/${tokenId}`
      }),
    }),
    // Fetches an NFT by its Wonder ID.
    fetchWonderNft: builder.query<Nft, number>({
      query: (nftId) => ({
        url: `/nfts/${nftId}`,
      }),
    }),
  }),
});
export default collectibleApi;
