import React, { useState } from 'react';
import { connect } from '../../crypto/metamask';
import { logger } from '../../../../shared/infra/logger';
import { styled } from '../../styles/theme';

const StyledButton = styled.button`
  background-color: #02eb46;
  border-radius: 5px;
  border: 1px solid #02eb46;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
`;

interface Props {
  onSuccess: () => void;
}

/**
 * A button to connect to the user's crypto wallet.
 *
 * Currently supports MetaMask (only).
 */
const ConnectWalletButton: React.FC<Props> = ({ onSuccess }) => {
  const [connecting, setConnecting] = useState<boolean>(false);

  const connectAction = async () => {
    setConnecting(true);

    connect()
      .then(() => {
        setConnecting(false);
        onSuccess();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const disabled = connecting;

  return (
    <StyledButton onClick={connectAction} disabled={disabled}>
      Connect MetaMask Wallet
    </StyledButton>
  );
};
export default ConnectWalletButton;
