import { environment } from '../../../../common/api/http';
import { ethers } from 'ethers';

export type EthereumNetwork = {
  networkish: ethers.providers.Networkish;
  chainId: number;
};

/**
 * The intended network for the current environment.
 * Any chain interactions (reads and writes) on behalf on Wonder should go to this network.
 *
 * Note that users may not be connected to the correct network
 *
 * This is currently expected to be a Networkish so that ethers can use it.
 *
 * Note that this is the network we expect.
 * The user may (likely accidentally) not be connected to the correct network when you try to interface via web3.
 *
 * See also:
 *  - https://docs.ethers.io/v5/api/providers/api-providers/#EtherscanProvider
 */
export let ethereumNetwork: EthereumNetwork;

switch (environment) {
  case 'local':
    // Local hardhat chain.
    ethereumNetwork = {
      networkish: 'http://localhost:8545',
      chainId: 1337,
    };
    break;
  case 'dev':
  case 'stage':
  case 'exp':
    ethereumNetwork = {
      networkish: 'ropsten',
      chainId: 3,
    };
    break;
  case 'prod':
    ethereumNetwork = {
      networkish: 'homestead',
      chainId: 1,
    };
    break;
  default:
    throw new Error('Invalid environment!');
}
