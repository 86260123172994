import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import routes from './routes';
import { styled } from '../styles/theme';
import { MOBILE_SIDE_MARGIN } from '../styles/layout';
import CollectionScreen from '../screens/collectibles/CollectionScreen';
import NftDetailScreen from '../screens/collectibles/NftDetailScreen';
import BrowseCollectionScreen from '../screens/collectibles/BrowseCollectionScreen';

// TODO: Revisit all this styling.

const ContentContainer = styled.div``;

const CollectiblesNavigator: React.FC = () => {
  let { path, url } = useRouteMatch();

  return (
    <>
      <ContentContainer>
        <Switch>
          <Route exact path={routes.collectibles.root}>
            <BrowseCollectionScreen />
          </Route>
          <Route exact path={routes.collectibles.collection.detail.root}>
            <CollectionScreen />
          </Route>
          <Route exact path={routes.collectibles.collection.detail.nfts.detail}>
            <NftDetailScreen />
          </Route>
          <Route path={`${path}/*`}>{/*TODO: 404 page.*/}</Route>
        </Switch>
      </ContentContainer>
    </>
  );
};
export default CollectiblesNavigator;
