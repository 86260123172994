import React from 'react';
import { ethers } from 'ethers';
import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';

interface Props {
  history: ethers.providers.Log[];
}

const HistoryTable = styled.table`
  width: 95%;
  margin: 0 auto;
  text-align: left;
  border-spacing: 0;
`;

const HistoryTableHead = styled.th`
  color: #c4c4c4;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  height: 35px;

  @media ${screens.medium} {
    font-size: 18px;
    height: 50px;
  }
`;

const HistoryTableHeadDesktop = styled(HistoryTableHead)`
  display: none;

  @media ${screens.medium} {
    display: table-cell;
  }
`;

const HistoryTableRow = styled.tr`
  height: 60px;

  @media ${screens.medium} {
    height: 75px;
  }
`;

const HistoryTableItem = styled.td`
  letter-spacing: 1px;
  font-size: 13px;
  border-top: 1px solid #c4c4c4;

  @media ${screens.medium} {
    font-size: 24px;
  }
`;

const HistoryTableItemType = styled(HistoryTableItem)`
  color: #02eb51;

  @media ${screens.medium} {
    color: #000000;
  }
`;

const HistoryTableItemTofrom = styled(HistoryTableItem)`
  display: none;

  @media ${screens.medium} {
    color: #02eb51;
    display: table-cell;
  }
`;

const HistoryTableItemDate = styled(HistoryTableItem)`
  width: 90px;

  @media ${screens.medium} {
    width: 160px;
  }
`;

const TransactionHistoryTable: React.FC<Props> = ({ history }) => {
  const rows = history.map((item) => {
    return (
      <HistoryTableRow key={item.transactionHash}>
        <HistoryTableItemType>Transfer</HistoryTableItemType>
        <HistoryTableItemTofrom>
          {ethers.utils.hexStripZeros(item.topics[1]).substring(0, 10)}
        </HistoryTableItemTofrom>
        <HistoryTableItemTofrom>
          {ethers.utils.hexStripZeros(item.topics[2]).substring(0, 10)}
        </HistoryTableItemTofrom>
        {/*<HistoryTableItem>*/}
        {/*  /!* 1.32 ETH <LightGray>/ $5.12M</LightGray>*!/*/}
        {/*</HistoryTableItem>*/}
        {/*<HistoryTableItemDate>10/31/2021</HistoryTableItemDate>*/}
      </HistoryTableRow>
    );
  });
  // TODO: Handle amount/date correctly.
  return (
    <HistoryTable>
      <thead>
        <tr>
          <HistoryTableHead>TYPE</HistoryTableHead>
          <HistoryTableHeadDesktop>FROM</HistoryTableHeadDesktop>
          <HistoryTableHeadDesktop>TO</HistoryTableHeadDesktop>
          {/*<HistoryTableHead>AMOUNT</HistoryTableHead>*/}
          {/*<HistoryTableHead>DATE</HistoryTableHead>*/}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </HistoryTable>
  );
};
export default TransactionHistoryTable;
