import { environment } from '../api/http';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { beforeDataDogSend, commonLogger } from './logger';

/**
 * Configure DataDog to log/monitor client-side apps.
 */
export const datadogClient = (service: string, version: string) => {
  if (environment === 'local') {
    commonLogger.info('Not initializing Datalog in LOCAL env');
  } else {
    // Datadog Real User Monitoring (RUM)
    datadogRum.init({
      applicationId: '07a0e1fd-acd0-433b-8776-51003e60cd21',
      clientToken: 'pub25fe4d13f8ff3a1add1e4d5f9f148ab4',
      site: 'datadoghq.com',
      service,
      env: environment,
      version,
      sampleRate: 100,
      trackInteractions: true,
    });

    datadogLogs.init({
      clientToken: 'pub9370d39cb70ba04f392739cbaf630051',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      env: environment,
      service,
      sampleRate: 100,
      version,
      // NOTE: Could be turned into an optional input, allowing for per-service config.
      beforeSend: beforeDataDogSend,
    });
  }
};
