import React, { useState } from 'react';
import { styled } from '../../../styles/theme';
import { useHistory } from 'react-router-dom';
import { screens } from '../../../styles/breakpoints';
import PlayButton from './PlayButton';
import DingDong from '../../../../../shared/features/dingdong/DingDong';

const playIconGray =
  require('../../../../static/images/play_icon_gray.png').default;
const playIconGreen =
  require('../../../../static/images/play_icon_green.png').default;
const arrowIconGray =
  require('../../../../static/images/arrow_icon_gray.png').default;
const arrowIconGreen =
  require('../../../../static/images/arrow_icon_green.png').default;

const DingDongRow = styled.div`
  border-top: 3px solid #000000;
  height: 60px;
  color: #c4c4c4;
  font-size: 19px;
  display: flex;
  align-items: center;

  @media ${screens.medium} {
    height: 80px;
    font-size: 30px;
  }

  &:hover {
    color: #000000;
  }
`;

const DingDongName = styled.div`
  margin-left: 31px;
`;

const ArrowIcon = styled.img`
  width: 30px;
  margin-right: 15px;
  margin-left: auto;

  @media ${screens.medium} {
    width: 46px;
    margin-right: 77px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const DingDongLink = styled.div`
  display: flex;
  align-items: center;

  flex: 1 0;
`;

interface Props {
  dingDong: DingDong;
  url?: string;
}

/**
 * A DingDong, to be displayed in a table-like UI.
 */
const DingDongRowItem: React.FC<Props> = ({ dingDong, url }) => {
  const history = useHistory();

  const gotoDetails = () => {
    if (url) {
      history.push(url);
    }
  };

  const [hovering, setHovering] = useState(false);

  const toggleHovering = () => {
    setHovering(!hovering);
  };

  return (
    <DingDongRow
      onMouseEnter={() => toggleHovering()}
      onMouseLeave={() => toggleHovering()}
    >
      <PlayButton
        onCollectionScreen={true}
        dingDong={dingDong}
        src={hovering ? playIconGreen : playIconGray}
      />
      <DingDongLink onClick={gotoDetails}>
        <DingDongName>{dingDong.name}</DingDongName>
        {url && <ArrowIcon src={hovering ? arrowIconGreen : arrowIconGray} />}
      </DingDongLink>
    </DingDongRow>
  );
};

export default DingDongRowItem;
