import {
  allEnvironments,
  Environment,
  setOverrideEnvironment,
} from './environment';
import { allServiceLocations } from './serviceLocations';

let environmentMatches = new Array<Environment>();

for (const locations of allServiceLocations) {
  for (const env of allEnvironments) {
    const url = locations[env];
    if (!url) continue;
    // Strip protocol
    const host = url.replace(/^https?:\/\//, '');
    if (window.location.host === host) {
      environmentMatches.push(env);
    }
  }
}

if (environmentMatches.length !== 1) {
  // Can't use other logger stuff, as it depends on environment
  console.log(
    `Unable to determine environment for ${window.location.host} with environmentMatches ${environmentMatches}`,
  );
} else {
  setOverrideEnvironment(environmentMatches[0]);
}
