import React from 'react';
import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';
import RandomButton from './RandomButton';

const PHRASE_INPUT_PLACEHOLDER = 'Type your DingDong phrase here';

const RANDOM_PHRASES: string[] = [
  "Frankly, my dear, I don't give a damn.",
  'Bazinga!',
];

type Props = {
  phrase: string;
  onChange: (string) => void;
};

const defaultProps: Props = {
  phrase: '',
  onChange: () => {},
};

const randomPhrase = () => {
  const index = Math.floor(Math.random() * RANDOM_PHRASES.length);
  return RANDOM_PHRASES[index];
};

// TODO: Width based on media size.
const Container = styled.div`
  width: 90%;

  display: flex;
  flex-direction: row;
`;

const TextInput = styled.input`
  height: 60px;
  font-size: 19px;

  @media ${screens.medium} {
    height: 84px;
    font-size: 24px;
  }

  min-width: 0;
  flex: 1 1 auto;

  border: solid;
  border-color: black;
  border-width: 2px;

  padding: 0 1em;

  &:focus {
    outline: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #c4c4c4;
  }
  :-ms-input-placeholder {
     color: #c4c4c4;
  }
`;

const PhraseInput: React.FC<Props> = ({ phrase, onChange }) => {
  const setRandomPhrase = () => {
    onChange(randomPhrase());
  };

  return (
    <Container>
      <TextInput
        type={'text'}
        placeholder={PHRASE_INPUT_PLACEHOLDER}
        value={phrase}
        onChange={(e) => onChange(e.target.value)}
      />
      <RandomButton mobileSize={'60px'} desktopSize={'84px'} onClick={setRandomPhrase} />
    </Container>
  );
};
PhraseInput.defaultProps = defaultProps;
export default PhraseInput;
