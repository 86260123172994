import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';

const Headline = styled.div`
  color: #000000;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  margin: 20px auto 40px auto;

  @media ${screens.medium} {
      font-size: 36px;
      line-height: 56px;
      max-width: 600px;
      margin: 25px auto 60px auto;
  }
`;
export default Headline;
