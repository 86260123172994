import React, { PropsWithChildren, useEffect, useState } from 'react';
import { logger } from '../../../../shared/infra/logger';
import {
  hasAccount as hasMetaMaskAccount,
  provider,
} from '../../crypto/metamask';
import ConnectWalletButton from './ConnectWalletButton';

interface Props {}

/**
 * Requires a connected crypto wallet.
 *
 * If no wallet is connected, shows a button to connect to a wallet.
 * Otherwise, shows the expected content.
 *
 * Currently, MetaMask only.
 */
const RequireWallet = ({ children }: PropsWithChildren<Props>) => {
  // Potentially add a loading state, since the user may have already connected but this component is still loading.
  const [hasAccount, setHasAccount] = useState<boolean>(false);

  useEffect(() => {
    hasMetaMaskAccount()
      .then((result) => setHasAccount(result))
      .catch((error) => {
        logger.error(error);
        // TODO: User-friendly error handling.
      });
  }, []);

  if (!hasAccount) {
    return <ConnectWalletButton onSuccess={() => setHasAccount(true)} />;
  } else {
    return <>{children}</>;
  }
};
export default RequireWallet;
