import React from 'react';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';

const TitleSection = styled.div`
background-color: #000000;
color: #ffffff;
text-transform: uppercase;
height: 23px;
width: 100%;
text-align: center;
font-size: 13px;
letter-spacing: 1px;
line-height: 23px;

@media ${screens.medium} {
  height: 50px;
  font-size: 24px;
  line-height: 50px;
}
`;

interface Props {
    title: string,
}

const PageTitle: React.FC<Props> = ({ title }) => {
    return (
        <TitleSection>{title}</TitleSection>
    )
}

export default PageTitle;