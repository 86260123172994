import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';

const TypeCardLink = styled(Link)`
width: 100vw;
height: 300px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin: 0 auto;
margin-left: 2.5vw;
margin-right: 2.5vw;
margin-top: 25px;

@media ${screens.medium} {
    width: 490px;
    margin-left: 5px;
    margin-right: 5px;
}
`

const TypeImage = styled.img`
height: 98px;
`

const Description = styled.p`
margin-bottom: 45px;
margin-top: 20px;
margin-left: auto;
margin-right: auto;
width: 100%;
padding-left: 2.5vw;
padding-right: 2.5vw;
font-size: 13px;
line-height: 20px;
letter-spacing: 1px;
text-align: center;

@media ${screens.medium} {
    font-size: 18px;
    line-height: 25px;
    width: 400px;
}
`

const TypeCard: React.FC<{link: string, image: string, text: string, background: string}> = (props) => {
    return (
        <div>
            <TypeCardLink to={props.link} style={{ backgroundColor: props.background }}>
                <TypeImage src={props.image} />
            </TypeCardLink>
            <Description>{props.text}</Description>
        </div>
    )
}

export default TypeCard;