import '../../../common/api/loadWebEnvironment'; // Load first to set environment.
import '../../shared/infra/datadogWeb';

import * as ReactDOM from 'react-dom';
import React from 'react';
import App from './App';

import './styles/style.css';

ReactDOM.render(<App />, document.getElementById('root'));
