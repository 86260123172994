import React from 'react';
import StyleIcon from '../../../../../shared/features/dingdong/StyleIcon';
import { styled } from '../../../styles/theme';

// TODO: Allow specifying icon size.
type Props = React.DOMAttributes<HTMLElement> & {
  icon: StyleIcon;
};

// TODO: Responsive sizing.
const Icon = styled.img`
  width: 6em;
  cursor: pointer;
`;

const StyleIcon: React.FC<Props> = ({ icon, onClick }) => {
  return (
    <>
      {icon && icon.srcAbsoluteUrl && (
        <Icon src={icon.srcAbsoluteUrl} onClick={onClick} />
      )}
    </>
  );
};
export default StyleIcon;
