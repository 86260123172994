import { styled } from '../styles/theme';

/**
 * A container that lays out its contents as a single horizontally-centered column.
 */
const CenterColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: spaced-center;
  align-items: center;
`;
export default CenterColumnContainer;
