import React from 'react';
import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';

const BORDER_WIDTH = '2px';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  // Would be nice if this auto-resized to preserve aspect ratio.
  mobileSize: string;
  desktopSize: string;
};

const defaultProps: Props = {
  mobileSize: '60px',
  desktopSize: '80px',
};

const DotContainer = styled.div`
  width: calc(100% - ${BORDER_WIDTH});
  height: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-content: space-evenly;
`;

// Height & width needs to be >= 25% and < 33.3% to make a 3x3 grid.
// The current values are picked based on an assumed size of 4em.
// At size=4em, the dot container is (3em - 4px) x (3em - 4px).
const Dot = styled.span`
  height: 0.75em;
  width: 0.75em;
  display: inline-block;

  border-style: solid;
  border-radius: 50%;
  border-width: 2px;
  border-color: rgb(196, 196, 196);
`;

const WhiteDot = styled(Dot)`
  background-color: white;
`;

const GrayDot = styled(Dot)`
  background-color: rgb(196, 196, 196);
`;

const RandomButton: React.FC<Props> = ({ className, mobileSize, desktopSize, onClick }) => {
  const dots = [...Array(9).keys()].map((i) => {
    // RNG value is arbitrary, but I like slightly more white dots.
    return Math.random() >= 0.55 ? <GrayDot key={i} /> : <WhiteDot key={i} />;
  });

  return (
    <button className={className} onClick={onClick}>
      <DotContainer>{dots}</DotContainer>
    </button>
  );
};
RandomButton.defaultProps = defaultProps;

const StyledRandomButton = styled(RandomButton)<Props>`
  height: ${(props) => props.mobileSize};
  width: ${(props) => props.mobileSize};

  @media ${screens.medium} {
    height: ${(props) => props.desktopSize};
    width: ${(props) => props.desktopSize};
  }

  flex: 0 0 auto;

  display: flex;
  justify-content: center;
  align-content: center;

  padding: 0.5em;

  background-color: white;

  border-style: solid solid solid none;
  border-color: black;
  border-width: 2px;
`;
export default StyledRandomButton;
