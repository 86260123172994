export interface Flag {
  key: string;
  defaultValue: boolean;
}

export const DINGDONG_ENABLE_PERSONAL = {
  key: 'dingdong-enable-personal',
  defaultValue: false,
};

export const ALL_DINGDONG_FLAGS = [DINGDONG_ENABLE_PERSONAL];
