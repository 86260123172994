import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import Principal from '../../../shared/features/verification/Principal';
import { BASE_DOMAIN } from './wonderConfig';
import RequestCodeResponse from '../../../shared/features/verification/RequestCodeResponse';
import VerifyCodeResponse from '../../../shared/features/verification/VerifyCodeResponse';

// NOTE: RTK Query recommends to combine API services from the same host.
const verificationApi = createApi({
  reducerPath: 'verificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_DOMAIN}/api/verify`,
  }),
  tagTypes: ['Principal'],
  endpoints: (builder) => ({
    fetchPrincipals: builder.query<Principal[], void>({
      query: () => ({
        url: 'principals',
        method: 'GET',
      }),
      providesTags: ['Principal'],
    }),
    requestCode: builder.mutation<void, Principal>({
      query: (principal) => ({
        url: 'request-code',
        method: 'POST',
        body: {
          principal: {
            type: principal.principalType,
            value: principal.principalValue,
          },
        },
      }),
    }),
    requestEthereumMessage: builder.mutation<string, Principal>({
      query: (principal) => ({
        url: 'request-code',
        method: 'POST',
        body: {
          principal: {
            type: principal.principalType,
            value: principal.principalValue,
          },
        },
      }),
      transformResponse: (response: RequestCodeResponse) =>
        response.ethereumMessage!,
    }),
    verifyCode: builder.mutation<
      VerifyCodeResponse,
      { principal: Principal; code: string }
    >({
      query: ({ principal, code }) => ({
        url: 'verify-code',
        method: 'POST',
        body: {
          principal: {
            type: principal.principalType,
            value: principal.principalValue,
          },
          code,
        },
      }),
      invalidatesTags: (result) => (result ? ['Principal'] : []),
    }),
  }),
});
export default verificationApi;
