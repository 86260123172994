import React, { useState } from 'react';
import StepContainer from '../../features/generate/components/StepContainer';
import routes from '../../navigation/routes';
import LinkButton from '../../components/LinkButton';
import styleApi from '../../services/styleApi';
import Style from '../../../../shared/features/dingdong/Style';
import StyleSelector from '../../features/generate/components/StyleSelector';
import GrowContent from '../../components/GrowContent';
import generateApi from '../../services/generateApi';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { skipToken } from '@reduxjs/toolkit/query';
import { BASE_DOMAIN } from '../../services/wonderConfig';
import Highlight from '../../components/Highlight';
import { GenerateActions } from '../../features/generate/generateSlice';
import { styled } from '../../styles/theme';
import { screens } from '../../styles/breakpoints';
import PageTitle from '../../components/PageTitle';
import Headline from '../../features/generate/components/Headline';

const topImage =
  require('../../../static/images/generate_step3.png').default;

const TopImage = styled.img`
  width: 96px;
  margin: 10px auto;
  
  @media ${screens.medium} {
    width: 152px;
    margin: 15px auto;
  }
`

const IconContainer = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
max-width: 840px;
margin-left: auto;
margin-right: auto;
justify-content: center;
`

const generating = <p>Generating DingDongs...</p>;
const failed = <p>Something went wrong!</p>;
const determineContent = (recording, melody, styles, handleStyleSelect) => {
  switch (recording.status) {
    case 'NOT_STARTED':
    // Shouldn't occur, but treat it as pending for now.
    // Fall through.
    case 'PENDING':
      return generating;
    case 'FAILURE':
      return failed;
    case 'SUCCESS':
      // Continue.
      break;
    default:
      throw 'Unexpected case!';
  }

  switch (melody.status) {
    case 'NOT_STARTED':
    // Shouldn't occur, but treat it as pending for now.
    // Fall through.
    case 'PENDING':
      return generating;
    case 'FAILURE':
      return failed;
    case 'SUCCESS':
      // Continue.
      break;
    default:
      throw 'Unexpected case!';
  }

  return <StyleSelector styles={styles} onSelect={handleStyleSelect} />;
};

const StylesScreen: React.FC = () => {
  const dispatch = useAppDispatch();

  const [selectedStyle, setSelectedStyle] = useState<Style | undefined>(
    undefined,
  );

  const {
    data: _styles,
    isFetching,
    isLoading,
    error,
  } = styleApi.useFetchAllStylesQuery();

  const recording = useAppSelector((state) => state.generate.recording);
  const melody = useAppSelector((state) => state.generate.melody);

  const isGenerating =
    recording.status === 'PENDING' || melody.status === 'PENDING';
  const generateFailure =
    recording.status === 'FAILURE' || melody.status === 'FAILURE';
  const generateSuccess =
    recording.status === 'SUCCESS' || melody.status === 'SUCCESS';

  const { data: dingDongStatus } = generateApi.useFetchGeneratedDingDongQuery(
    selectedStyle
      ? {
          melodyId: melody.id,
          styleId: selectedStyle.id,
        }
      : skipToken,
  );

  let styles = isLoading ? [] : _styles.filter((s) => s.enabled);

  const handleStyleSelect = (style: Style) => {
    // TODO: Play dingdong.

    setSelectedStyle(style);

    const audio = new Audio(`${BASE_DOMAIN}/api/generate/melodies/${melody.id}/dingdong?styleId=${style.id}`);
    audio.play();
  };

  const content = determineContent(
    recording,
    melody,
    styles,
    handleStyleSelect,
  );

  const disableContinue = !selectedStyle;

  const continueAction = () => {
    dispatch(
      GenerateActions.saveDingDong({
        melodyId: melody.id,
        styleId: selectedStyle.id,
      }),
    );
  };

  // NOTE: Would be nice to RTK query/cache the actual audio file, not the status.
  return (
    <>
      <PageTitle title="Personal" />
      <StepContainer title={''}>
        <TopImage src={topImage} />
        <Headline>
          Select your <Highlight>music style</Highlight>
        </Headline>
        <IconContainer>{content}</IconContainer>
      </StepContainer>
      <LinkButton
        to={routes.generate.register}
        onClick={continueAction}
        disabled={disableContinue}
      >
        Continue
      </LinkButton>
    </>
  );
};
export default StylesScreen;
