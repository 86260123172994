import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import ManageDingDongsScreen from '../screens/management/ManageDingDongsScreen';
import Header from '../components/Header';
import SubNav from '../components/SubNav';
import LogoSection from '../components/LogoSection';
import Footer from '../components/Footer';
import CenterColumnContainer from '../components/CenterColumnContainer';
import VerifyAddressScreen from '../screens/management/VerifyAddressScreen';

const ManagementNavigator: React.FC = () => {
  return (
    <>
      <CenterColumnContainer>
        <Header />
        <SubNav />
        <LogoSection />
        <Switch>
          <Route exact path={routes.management.root}>
            <ManageDingDongsScreen />
          </Route>
          <Route path={routes.management.verify.address}>
            <VerifyAddressScreen />
          </Route>
        </Switch>
        <Footer />
      </CenterColumnContainer>
    </>
  );
};
export default ManagementNavigator;
