export let isDevMode = false;
export let overrideEnvironment: Environment | undefined;

if (typeof process !== 'undefined') {
  isDevMode = process.env.NODE_ENV !== 'production';
  overrideEnvironment = process.env.HTTP_ENVIRONMENT as Environment;
}

export const setOverrideEnvironment = (env: Environment) => {
  overrideEnvironment = env;
};

export type Environment = 'local' | 'exp' | 'dev' | 'stage' | 'prod';

export const environmentNames: { [env in Environment]: string } = {
  local: 'Localhost',
  exp: 'Experiment',
  dev: 'Development',
  stage: 'Staging',
  prod: 'Production',
};

export const environmentMatomoSiteIds: { [env in Environment]: number } = {
  local: 4,
  exp: 6,
  dev: 3,
  stage: 2,
  prod: 1,
};

export const environmentLaunchDarklyClientSideIds: {
  [env in Environment]: string;
} = {
  local: '609a87e31ae0a8114d833ee3',
  exp: '610febbdc0d08a266708bed7',
  dev: '609a85f23d03ad0fd568e23e',
  stage: '609a869e1ae0a8114d833e4d',
  prod: '609a86c83d03ad0fd568e2be',
};

export const allEnvironments = Object.keys(environmentNames) as Environment[];

export const environmentColors = {
  local: 'cyan',
  exp: 'orange',
  dev: 'green',
  stage: 'yellow',
  prod: 'red',
};
