import React, { createRef, RefObject } from 'react';
import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';
import Nft from '../../../../../shared/features/collectibles/Nft';
import DingDong from '../../../../../shared/features/dingdong/DingDong';

interface Props {
  onCollectionScreen?: boolean;
  dingDong?: DingDong;
  src?: string;
  onClickEvent?: () => void;
}

const PlayButton: React.FC<Props> = ({
  onCollectionScreen,
  dingDong,
  src,
  onClickEvent,
}) => {
  const Play = styled.img`
    width: ${onCollectionScreen ? '32px' : '60px'};
    height: ${onCollectionScreen ? '32px' : '60px'};
    cursor: pointer;
    margin-left: ${onCollectionScreen ? '15px' : '0px'};

    @media ${screens.medium} {
      width: ${onCollectionScreen ? '50px' : '100px'};
      height: ${onCollectionScreen ? '50px' : '100px'};
      margin-left: ${onCollectionScreen ? '73px' : '0px'};
    }
  `;

  const audioRef: RefObject<HTMLAudioElement> = createRef();

  const play = () => {
    audioRef.current?.play();
    {
      onClickEvent && onClickEvent();
    }
  };

  return (
    <>
      <Play src={src} onClick={() => play()} />
      <audio ref={audioRef} controls={false} hidden>
        <source src={dingDong.audioAbsoluteUrl} />
      </audio>
    </>
  );
};

export default PlayButton;
