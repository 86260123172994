import { include } from 'named-urls';

const routes = {
  root: '',
  generate: include('/generate', {
    root: '',
    phrase: 'phrase',
    record: 'record',
    styles: 'styles',
    register: 'congrats',
  }),
  collectibles: include('/collectibles', {
    root: '',
    // TODO: collection id
    collection: include('collections', {
      detail: include(':collectionId', {
        root: '',
        nfts: include('nfts', {
          detail: ':nftTokenId',
        }),
      }),
    }),
  }),
  management: include('/me', {
    root: '',
    verify: include('verify', {
      address: 'address',
    }),
  }),
};
export default routes;
