import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { environment, launchDarklyClientIdForEnv } from '../../api/http';
import { Flag, FlagValue } from '../../featureflags/RoamFlagConfigs';
import { getUserFromDingDong } from '../../featureflags/UserKeys';

export class DingDongFlags {
  private static instance: DingDongFlags;
  private ready: boolean;
  private ldClient: LDClient;

  private constructor(hash: string) {
    const user = getUserFromDingDong();
    this.ldClient = initialize(launchDarklyClientIdForEnv, user, { hash });
    this.ready = false;
  }

  public static getInstance(hash: string): DingDongFlags {
    if (!DingDongFlags.instance) {
      DingDongFlags.instance = new DingDongFlags(hash);
    }
    return DingDongFlags.instance;
  }

  public isReady() {
    return this.ready;
  }

  public async waitForReady(onChange: () => void) {
    await this.ldClient.waitForInitialization();
    // Just listening to "change" event opens a streaming connection to LaunchDarkly
    // so client can get live updates to flags changes.
    this.ldClient.on('change', (_changedFlags) => onChange());
    this.ready = true;
  }

  public checkFlag<T extends FlagValue>(flag: Flag<T>): T {
    if (!this.ready) {
      throw new Error('DingDong feature flags not ready yet');
    }
    const value: unknown = this.ldClient.variation(flag.key, flag.defaultValue);
    if (typeof value !== typeof flag.defaultValue) {
      return flag.defaultValue;
    }
    return value as T;
  }
}
