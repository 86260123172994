import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { GenerateActions } from '../features/generate/generateSlice';
import StepContainer from '../features/generate/components/StepContainer';
import routes from '../navigation/routes';
import LinkButton from '../components/LinkButton';
import CenterColumnContainer from '../components/CenterColumnContainer';
import { styled } from '../styles/theme';
import PhraseInput from '../features/generate/components/PhraseInput';
import Highlight from '../components/Highlight';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomepageMain from '../components/HomepageMain';
import FeaturedApps from '../components/FeaturedApps';
import { FeatureFlagSelectors } from '../features/featureflags/featureFlagsSlice';
import { DINGDONG_ENABLE_PERSONAL } from '../../../../common/featureflags/DingDongFlagConfigs';

const MainContent = styled.div`
  flex: 1 1 auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Rename...
const Footnote = styled.div`
  margin: 1em 0;

  text-align: center;
`;

const heroImage =
  require('../../static/images/home_hero_wallpaper.svg').default;

// TODO: Figure out styling for large screens.
const Hero = styled.div`
  width: 100%;
  content: url('${heroImage}');
`;

const HomeScreen: React.FC = () => {
  const [phrase, setPhrase] = useState<string>('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GenerateActions.reset());
  }, []);

  const continueAction = () => {
    dispatch(GenerateActions.setPhrase(phrase));
  };

  return (
    <CenterColumnContainer>
      <Header />
      <Hero />
      <HomepageMain />
      <Footer />
    </CenterColumnContainer>
  );
};
export default HomeScreen;
