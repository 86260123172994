import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import DingDong from '../../../shared/features/dingdong/DingDong';
import { BASE_DOMAIN } from './wonderConfig';

const dingDongApi = createApi({
  reducerPath: 'dingDongApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_DOMAIN}/dingdongs`,
  }),
  endpoints: (builder) => ({
    fetchDingDong: builder.query<DingDong, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
    }),
  }),
});
export default dingDongApi;
