import React, { useState } from 'react';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';

const NavContainer = styled.nav`
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #808080;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1px;

  @media ${screens.medium} {
      height: unset;
  }
`

const LogoLink = styled.a`
  margin-left: 20px;
`

const Logo = styled.img`
  width: 70px;

  @media ${screens.medium} {
      width: 74px;
  }
`

const MenuItemsList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
`

const MenuItemLink = styled.a`
  text-decoration: none;
  display: inline;
  color: inherit;

  &:hover {
    background-color: #d200e6;
  }
`

const MenuItemAbout = styled.li`
  display: none;

  @media ${screens.medium} {
    display: block;
    width: 118px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const MenuItem = styled.li`
  width: 118px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MenuItemHamburger = styled.li`
  display: list-item;
  margin-right: 7vw;
  cursor: pointer;
  height: 100%;
  width: unset;

  @media ${screens.medium} {
      display: none;
  }
`

const HamburgerImage = styled.img`
  width: 8vw;
`

const CloseContainer = styled.div`
text-align: right;
`

const CloseButton = styled.img`
width: 5vw;
padding-top: 3vh;
padding-right: 5vw;
box-sizing: content-box;

&:after {
  box-sizing: content-box;
}

&:before {
  box-sizing: content-box;
}
`

const MobileNavList = styled.ul`
margin-top: 8vh;
margin-left: 5vw;
margin-right: 5vw;
`

const MobileNavListItem = styled.li`
list-style: none;
margin-bottom: 2vh;
color: #ffffff;
font-size: 9vw;
`

const MobileNavLink = styled.a`
text-decoration: none;
font-size: 9vw;
color: #ffffff;
`

const Header: React.FC = () => {
  const [showMobilemenu, setShowMobilemenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobilemenu(!showMobilemenu);
  };

  const MobileMenuContainer = styled.div`
  display: ${ showMobilemenu ? 'block' : 'none' };
  height: 100vh;
  width: 100vw;
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  `
  
    return (
      <>
        <NavContainer>
            <LogoLink href="https://www.wonder.inc/">
                <Logo src="https://www.wonder.inc/wonder-logo-white.png" />
            </LogoLink>
            <MenuItemsList>
                <MenuItemLink href="https://www.wonder.inc/about-us">
                    <MenuItemAbout>About Us</MenuItemAbout>
                </MenuItemLink>
                <MenuItemLink href="https://accounts.wonder.inc/login">
                    <MenuItem>Sign In</MenuItem>
                </MenuItemLink>
                    <MenuItemHamburger onClick={() => toggleMobileMenu()}>
                        <HamburgerImage src="https://www.wonder.inc/hamburger.png" />
                    </MenuItemHamburger>
            </MenuItemsList>
        </NavContainer>
        <MobileMenuContainer>
          <CloseContainer>
            <CloseButton src="https://www.wonder.inc/close.png" onClick={() => toggleMobileMenu()} />
          </CloseContainer>
          <MobileNavList>
            <MobileNavListItem>
              <MobileNavLink href="https://www.wonder.inc/">Home</MobileNavLink>
            </MobileNavListItem>
            <MobileNavListItem>
              <MobileNavLink href="https://www.wonder.inc/roam">Roam</MobileNavLink>
            </MobileNavListItem>
            <MobileNavListItem>
              <MobileNavLink href="https://www.wonder.inc/dynascore">Dynascore</MobileNavLink>
            </MobileNavListItem>
            <MobileNavListItem>
              <MobileNavLink href="https://dingdong.wonder.inc/">DingDong</MobileNavLink>
            </MobileNavListItem>
            <MobileNavListItem>
              <MobileNavLink href="https://www.wonder.inc/about-us">About Us</MobileNavLink>
            </MobileNavListItem>
          </MobileNavList>
        </MobileMenuContainer>
      </>
    )
}

export default Header;