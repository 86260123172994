import React, { useEffect, useState } from 'react';
import MainContent from '../../components/MainContent';
import managementApi from '../../services/managementApi';
import verificationApi from '../../services/verificationApi';
import { styled } from '../../styles/theme';
import DescriptionParagraph from '../../features/collectibles/components/DescriptionParagraph';
import _ from 'lodash';
import DingDongRowItem from '../../features/collectibles/components/DingDongRowItem';
import Principal from '../../../../shared/features/verification/Principal';
import routes from '../../navigation/routes';
import { useHistory } from 'react-router-dom';
import Ownership from '../../../../shared/features/management/Ownership';

const DescriptionParagraphSpaced = styled(DescriptionParagraph)`
  margin-top: 25px;
`;

const DingDongList = styled.div`
  width: 100%;
`;

const StyledButton = styled.button`
  background-color: #02eb46;
  border-radius: 5px;
  border: 1px solid #02eb46;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
`;

const ManageDingDongsScreen: React.FC = () => {
  const history = useHistory();

  const { data: principals, isSuccess: isPrincipalsSuccess } =
    verificationApi.useFetchPrincipalsQuery();

  const {
    data: ownerships,
    isSuccess: isOwnershipsSuccess,
    isFetching: isOwnershipsFetching,
    refetch: refetchOwnerships,
  } = managementApi.useFetchOwnershipQuery();
  // Assume different types will always be unique.
  const ownershipsByPrincipal = isOwnershipsSuccess
    ? _.groupBy(ownerships, (o) => o.principal.principalValue)
    : {};

  const {
    data: dingDongs,
    isSuccess: isDingDongsSuccess,
    isFetching: isDingDongsFetching,
    refetch: refetchDingDongs,
  } = managementApi.useFetchOwnedDingDongsQuery();
  const indexedDingDongs = isDingDongsSuccess
    ? _.keyBy(dingDongs, (dingDong) => dingDong.id)
    : {};

  useEffect(() => {
    // Hack to reload everything on principal change.
    refetchOwnerships();
    refetchDingDongs();
  }, [principals]);

  if (isPrincipalsSuccess && principals.length === 0) {
    return (
      <MainContent>
        <DescriptionParagraphSpaced>
          Please verify your email or Ethereum address to see DingDongs owned by
          that account.
        </DescriptionParagraphSpaced>
        <DescriptionParagraph>
          <StyledButton
            onClick={() => {
              history.push(routes.management.verify.address);
            }}
          >
            Verify Account
          </StyledButton>
        </DescriptionParagraph>
      </MainContent>
    );
  }

  const principalDingDongComponents = (principal: Principal) => {
    // Failed or (re)fetching.
    if (!isDingDongsSuccess || isDingDongsFetching) {
      return <></>;
    }

    const ownerships: Ownership[] =
      ownershipsByPrincipal[principal.principalValue] ?? [];
    if (ownerships.length === 0) {
      return <>This account does not own any DingDongs.</>;
    }

    const dingDongRows = ownerships.map((o) => (
      <DingDongRowItem
        key={o.dingDongId}
        dingDong={indexedDingDongs[o.dingDongId]}
      />
    ));
    return <DingDongList>{dingDongRows}</DingDongList>;
  };

  const principalDingDongs = isPrincipalsSuccess ? (
    principals.map((principal) => {
      const dingDongs = principalDingDongComponents(principal);
      return (
        <React.Fragment key={principal.principalValue}>
          <DescriptionParagraph>
            {principal.principalValue}
          </DescriptionParagraph>
          {dingDongs}
        </React.Fragment>
      );
    })
  ) : (
    <></>
  );

  const principalOverview = (
    <>
      <DescriptionParagraph>Accounts</DescriptionParagraph>
      {principalDingDongs}
    </>
  );

  const mainContent = <>{principalOverview}</>;

  return <MainContent children={mainContent} />;
};
export default ManageDingDongsScreen;
