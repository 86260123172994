import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_DOMAIN } from './wonderConfig';
import Ownership from '../../../shared/features/management/Ownership';
import DingDong from '../../../shared/features/dingdong/DingDong';

const managementApi = createApi({
  reducerPath: 'managementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_DOMAIN}/api/management`,
  }),
  endpoints: (builder) => ({
    fetchOwnership: builder.query<Ownership[], void>({
      query: () => ({
        url: 'ownership',
        method: 'GET',
      }),
    }),
    fetchOwnedDingDongs: builder.query<DingDong[], void>({
      query: () => ({
        url: 'owned-dingdongs',
        method: 'GET',
      }),
    }),
  }),
});
export default managementApi;
