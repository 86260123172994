import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '../styles/theme';
import { screens } from '../styles/breakpoints';
import routes from '../navigation/routes';
import { useAppSelector } from '../app/hooks';
import { FeatureFlagSelectors } from '../features/featureflags/featureFlagsSlice';
import { DINGDONG_ENABLE_PERSONAL } from '../../../../common/featureflags/DingDongFlagConfigs';

interface NavItem {
  label: string;
  url: string;
}

interface Props {}

const SubNavContainer = styled.div`
  display: none;

  @media ${screens.medium} {
    display: block;
    width: 100%;
    background-color: #d9d9d9;
    letter-spacing: 1px;
    height: 53px;
    font-size: 18px;
  }
`;

const SubNavList = styled.ul`
  list-style: none;
  width: 94%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
`;

const SubNavItem = styled.li`
  display: inline;
  margin-right: 40px;
`;

const SubNavLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

// Nav Items

const personal = {
  label: 'Personal',
  url: routes.generate.root,
};

const collectible = {
  label: 'Collectible',
  url: routes.collectibles.root,
};

const SubNav: React.FC<Props> = () => {
  const enablePersonalFlag = useAppSelector(
    FeatureFlagSelectors.selectFlag(DINGDONG_ENABLE_PERSONAL),
  );

  const navItems: NavItem[] = [];
  if (enablePersonalFlag) {
    navItems.push(personal);
  }
  navItems.push(collectible);

  return (
    <SubNavContainer>
      <SubNavList>
        {navItems.map((item) => {
          return (
            <SubNavItem key={item.label}>
              <SubNavLink to={item.url}>{item.label}</SubNavLink>
            </SubNavItem>
          );
        })}
      </SubNavList>
    </SubNavContainer>
  );
};

export default SubNav;
