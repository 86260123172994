import { datadogLogs, StatusType } from '@datadog/browser-logs';
import pino from 'pino';

import { environment } from '../api/http';

// USAGE
// Create a logger.ts for your app, which creates and exports a logger instance.
// export const logger = createLogger("serviceName");

// https://github.com/pinojs/pino-pretty
const prettyPrintSettings =
  environment === 'local' ? { colorize: true } : false;

const formatters = {
  level(label: string, _number: number) {
    return { level: label };
  },
};

let logLevel: string;
switch (environment) {
  case 'local':
    logLevel = 'trace';
    break;
  case 'exp':
  case 'dev':
    logLevel = 'debug';
    break;
  case 'stage':
  case 'prod':
    logLevel = 'info';
    break;
  default:
    throw new Error('Unexpected environment!');
}

export const loggers: Map<string, pino.Logger> = new Map();

let logMethodOverride: pino.LogFn;
export const setLogMethodOverride = (overrideMethod: pino.LogFn) => {
  logMethodOverride = overrideMethod;
};

export const createLogger = (name: string, discardLogs: boolean = false) => {
  const options: pino.LoggerOptions = {
    name: name,
    level: logLevel,
    prettyPrint: prettyPrintSettings,
    formatters,
    hooks: {
      // Right now error function is only set in
      // Roam Electron, but this makes it so logger.error and
      // logger.fatal in the main process can send
      // an IPC to the logger window to log the error
      logMethod(inputArgs, method, level) {
        if (discardLogs) {
          return;
        }

        if (inputArgs.length > 0) {
          const arg1 = inputArgs.shift() as string;
          if (logMethodOverride) {
            return logMethodOverride.apply(this, [arg1, ...inputArgs, level]);
          }

          return method.apply(this, [arg1, ...inputArgs]);
        }
      },
    },
    // This causes logger.[warn|error|fatal] calls
    // in browser side code to send to datadog if it is configured
    browser: {
      write: {
        trace: (obj) => {
          const logObj = obj as { msg: any };
          console.trace(logObj.msg);
        },
        debug: (obj) => {
          const logObj = obj as { msg: any };
          console.debug(logObj.msg);
        },
        info: (obj) => {
          const logObj = obj as { msg: any };
          console.info(logObj.msg);
        },
        warn: (obj) => {
          const logObj = obj as { msg: any };
          console.warn(logObj.msg);
        },
        error: (obj) => {
          const logObj = obj as { msg: any };
          console.error(logObj.msg);
        },
      },
      transmit: {
        level: 'info',
        send: (level, logEvent) => {
          let logMessage = '';
          let logPayload = {};
          if (logEvent.messages.length === 1) {
            logMessage = logEvent.messages[0] as string;
          } else if (logEvent.messages.length === 2) {
            logMessage = logEvent.messages[1] as string;
            logPayload = logEvent.messages[0] as object;
          }

          if (logMessage) {
            datadogLogs.logger.log(
              logMessage,
              logPayload,
              logEvent.level.label as StatusType,
            );
          }
        },
      },
    },
  };
  const logger = discardLogs
    ? pino(options, {
        write: (msg: string) => {},
      })
    : pino(options);
  loggers.set(name, logger);
  return logger;
};

// Please use this logger only for common/* files.
// Apps should create and use their own logger instance.
export const commonLogger = createLogger('common');

export const beforeDataDogSend = (log: any) => {
  const str = JSON.stringify(log);
  if (
    /* Errors that can be safely ignored */
    str.includes('ResizeObserver loop limit exceeded') ||
    str.includes('The operation could not be performed and was aborted') ||
    str.includes('Requested device not found') ||
    str.includes('Possible EventEmitter')
  ) {
    return false;
  }
};
