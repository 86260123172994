import React, { useEffect, useState } from 'react';
import { styled } from '../../../styles/theme';
import { detect } from 'detect-browser';
import { logger } from '../../../../../shared/infra/logger';

const RECORD_BUTTON_INACTIVE =
  require('../../../../static/images/record_button.png').default;
const RECORD_BUTTON_ACTIVE =
  require('../../../../static/images/red_circle.png').default;
const MAX_RECORDING_LENGTH_MS = 5000;

// These should be ordered from most to least preferred.
// Though we don't actually have a preferred format right now.
const AUDIO_TYPE_OPTIONS = ['audio/webm', 'audio/mp4', 'audio/wav'];

const audioType: string | undefined = (() => {
  if (!window.MediaRecorder) {
    return undefined;
  }
  if (!MediaRecorder.isTypeSupported) {
    return undefined;
  }
  return AUDIO_TYPE_OPTIONS.find((type) => {
    return MediaRecorder.isTypeSupported(type);
  });
})();

// We can set audio bits per second here if needed.
const MEDIA_RECORDER_OPTIONS = {
  mimeType: `${audioType}`,
};

const browser = detect();

type Props = {
  onRecordingComplete?: (string) => void;
} & React.HTMLAttributes<HTMLButtonElement>;

const acquireStream = async (): Promise<MediaStream> => {
  return navigator.mediaDevices.getUserMedia({
    video: false,
    audio: true,
  });
};

const cleanupStream = (stream: MediaStream) => {
  stream.getAudioTracks().forEach((track) => {
    track.stop();
  });
};

const chunks = [];

const RecordButton: React.FC<Props> = ({ onRecordingComplete }: Props) => {
  const [permissions, setPermissions] = useState<PermissionState>();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioStream, setAudioStream] = useState<MediaStream | undefined>();
  const [recorder, setRecorder] = useState<MediaRecorder | undefined>(
    undefined,
  );

  // TODO: Pushed state.
    const StyledRecordButton = styled.button`
    background-image: url(${ isRecording ? RECORD_BUTTON_ACTIVE : RECORD_BUTTON_INACTIVE});
    background-size: contain;
    width: 150px;
    height: 150px;
    cursor: pointer;

    border-radius: 50%;
    border: none;
    `;

  // NOTE: navigator.permissions does not exist on Safari.
  const permissionsSupported = browser.name !== 'safari';

  // TODO: Could probably use styling.
  if (!audioType) {
    logger.error('Browser does not support media recording!', browser);
    return (
      <p>
        Sorry, the device or browser you are using does not support media
        recording!
      </p>
    );
    throw Error('bad audio type');
  }

  useEffect(() => {
    let cancel = false;

    if (permissionsSupported) {
      // @ts-ignore
      navigator.permissions?.query({ name: 'microphone' }).then((status) => {
        if (cancel) {
          return;
        }
        setPermissions(status.state);
      });
    }

    return () => {
      cancel = true;
    };
  });

  useEffect(() => {
    if (audioStream) {
      return () => {
        cleanupStream(audioStream);
      };
    }
  }, [audioStream]);

  const startRecording = async () => {
    if (isRecording) {
      return;
    }

    const stream = await acquireStream();
    setAudioStream(stream);

    // This is triggered before the user grants microphone permissions.
    // We end here (and clean anything up) because the user has had to release the record button to accept mic permissions.
    // Assume that the user will have to retry if we need to request permissions.
    if (permissionsSupported && permissions != 'granted') {
      cleanupStream(stream);
      return;
    }

    const mediaRecorder = new MediaRecorder(stream, MEDIA_RECORDER_OPTIONS);
    setRecorder(mediaRecorder);

    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
    setTimeout(stopRecording, MAX_RECORDING_LENGTH_MS);

    setIsRecording(true);
  };

  const stopRecording = () => {
    if (!isRecording) {
      return;
    }

    recorder?.stop();
    setIsRecording(false);

    cleanupStream(audioStream);
    setAudioStream(undefined);
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunks.push(event.data);
      const blob = new Blob(chunks, {
        type: audioType,
      });

      const url = URL.createObjectURL(blob);
      onRecordingComplete(url);
    } else {
      // TODO: Handle gracefully.
      logger.error('no data');
    }
  };

  return (
    <StyledRecordButton
      onMouseDown={startRecording}
      onTouchStart={startRecording}
      onMouseUp={stopRecording}
      onTouchEnd={stopRecording}
      type={'submit'}
    ></StyledRecordButton>
  );
};

export default RecordButton;
