import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';

const DescriptionParagraph = styled.p`
  font-size: 19px;
  line-height: 26px;
  letter-spacing: 1px;
  margin: 0px 20px;
  text-align: center;
  margin-bottom: 25px;

  @media ${screens.medium} {
    font-size: 24px;
    line-height: 32px;
    width: 100%;
    max-width: 810px;
  }
`;

export default DescriptionParagraph;
