import React from 'react';
import { styled } from '../../../styles/theme';
import { screens } from '../../../styles/breakpoints';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  title?: string;
};

const Title = styled.div`
  // font-family: 'Courier New', sans-serif;
  color: rgb(107, 230, 103);
`;

const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

/**
 * Generate dingdong step container.
 */
const StepContainer: React.FC<Props> = ({ className, title, children }) => {
  return (
    <div className={className}>
      {title && <Title>{title}</Title>}
      <Content>{children}</Content>
    </div>
  );
};

const StyledStepContainer = styled(StepContainer)`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default StyledStepContainer;
