import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GenerateActions,
  GenerateMelodyRequest,
  SaveDingDongRequest,
  UploadRecordingRequest,
} from './generateSlice';
// eslint-disable-next-line import/named
import { PayloadAction } from '@reduxjs/toolkit';
import {
  requestGenerateMelody,
  requestUploadRecording,
  requestSaveDingDong,
} from './generateService';

/**
 * Handles an upload recording action.
 *
 * This uploads a recording.
 * On success, triggers melody generation for that recording.
 */
function* uploadRecording(action: PayloadAction<UploadRecordingRequest>) {
  yield put(GenerateActions.setRecording({ status: 'PENDING' }));
  const { phrase, recordingUri } = action.payload;
  const { recordingId, error } = yield call(
    requestUploadRecording,
    phrase,
    recordingUri,
  );
  if (error) {
    yield put(GenerateActions.uploadRecordingFailure());
  } else {
    yield put(GenerateActions.uploadRecordingSuccess(recordingId));
  }
}

function* uploadRecordingSuccess(action: PayloadAction<number>) {
  const recordingId = action.payload;
  yield put(
    GenerateActions.setRecording({ id: recordingId, status: 'SUCCESS' }),
  );
  yield put(GenerateActions.generateMelody({ recordingId }));
}

function* uploadRecordingFailure() {
  yield put(GenerateActions.setRecording({ status: 'FAILURE' }));
}

function* generateMelody(action: PayloadAction<GenerateMelodyRequest>) {
  yield put(GenerateActions.setMelody({ status: 'PENDING' }));
  const { recordingId } = action.payload;
  const { melodyId, error } = yield call(requestGenerateMelody, recordingId);

  if (error) {
    yield put(GenerateActions.generateMelodyFailure());
  } else {
    yield put(GenerateActions.generateMelodySuccess(melodyId));
  }
}

function* generateMelodySuccess(action: PayloadAction<number>) {
  const melodyId = action.payload;
  yield put(GenerateActions.setMelody({ id: melodyId, status: 'SUCCESS' }));
}

function* generateMelodyFailure() {
  yield put(GenerateActions.setMelody({ status: 'FAILURE' }));
}

function* saveDingDong(action: PayloadAction<SaveDingDongRequest>) {
  const { melodyId, styleId } = action.payload;
  const { dingDongId } = yield call(requestSaveDingDong, melodyId, styleId);
}

function* generateSaga() {
  yield takeLatest(GenerateActions.uploadRecording, uploadRecording);
  yield takeEvery(
    GenerateActions.uploadRecordingSuccess,
    uploadRecordingSuccess,
  );
  yield takeEvery(
    GenerateActions.uploadRecordingFailure,
    uploadRecordingFailure,
  );
  yield takeLatest(GenerateActions.generateMelody, generateMelody);
  yield takeEvery(GenerateActions.generateMelodySuccess, generateMelodySuccess);
  yield takeEvery(GenerateActions.generateMelodyFailure, generateMelodyFailure);
  yield takeEvery(GenerateActions.saveDingDong, saveDingDong);
}
export default generateSaga;
