export const getUserFromAccount = (accountId: number) => {
  return {
    key: 'a' + accountId.toString(),
  };
};

export const getUserFromRoam = (roamId: number) => {
  return {
    key: 'r' + roamId.toString(),
  };
};

export const getUserFromDingDong = () => {
  return {
    key: 'dingdong',
  };
};

export const getRoamGlobalUser = () => {
  return {
    key: 'roam-global',
  };
};
