import { configureStore } from '@reduxjs/toolkit';
import generateReducer from '../features/generate/generateSlice';
import dingDongApi from '../services/dingDongApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import verificationApi from '../services/verificationApi';
import { combineReducers } from 'redux';
import verificationReducer from '../features/verification/verificationSlice';
import featureFlagsReducer from '../features/featureflags/featureFlagsSlice';
import { rootSaga } from './sagas';
import createSagaMiddleware from 'redux-saga';
import styleApi from '../services/styleApi';
import generateApi from '../services/generateApi';
import collectibleApi from '../services/collectibleApi';
import cryptoApi from '../services/cryptoApi';
import managementApi from '../services/managementApi';

const rootReducer = combineReducers({
  featureFlags: featureFlagsReducer,
  generate: generateReducer,
  verification: verificationReducer,
  [dingDongApi.reducerPath]: dingDongApi.reducer,
  [styleApi.reducerPath]: styleApi.reducer,
  [generateApi.reducerPath]: generateApi.reducer,
  [collectibleApi.reducerPath]: collectibleApi.reducer,
  [verificationApi.reducerPath]: verificationApi.reducer,
  [managementApi.reducerPath]: managementApi.reducer,
  [cryptoApi.reducerPath]: cryptoApi.reducer,
});

// NOTE:
// Disabling a bunch of stuff, I believe there's just no TS typings.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
// const storage =
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
//   new CookieStorage(Cookies);

// const persistConfig = {
//   key: 'root',
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//   storage: storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      sagaMiddleware,
      dingDongApi.middleware,
      styleApi.middleware,
      generateApi.middleware,
      collectibleApi.middleware,
      verificationApi.middleware,
      managementApi.middleware,
      cryptoApi.middleware,
    ),
});
sagaMiddleware.run(rootSaga);
export default store;

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
