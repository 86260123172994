import React, { useState } from 'react';
import StepContainer from '../../features/generate/components/StepContainer';
import routes from '../../navigation/routes';
import LinkButton from '../../components/LinkButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { GenerateActions } from '../../features/generate/generateSlice';
import { styled } from '../../styles/theme';
import { screens } from '../../styles/breakpoints';
import GrowContent from '../../components/GrowContent';
import Highlight from '../../components/Highlight';
import RecordButton from '../../features/generate/components/RecordButton';
import PageTitle from '../../components/PageTitle';
import Headline from '../../features/generate/components/Headline';

// FUTURE:
//  - Consider uploading recording & triggering melody generation immediately to reduce perceived latency.
//    - Whether or not this is feasible depends on backend performance (is it costly to do a lot of upload/meelody generation?).

const MICROPHONE_ICON =
  require('../../../static/icons/microphone_icon_full_color_temp.png').default;

const MicrophoneImage = styled.img`
width: 101px;
margin: 10px auto;

@media ${screens.medium} {
  width: 158px;
  margin: 15px auto;
}
`;

const RecordingLengthNotice = styled.div`
text-align: center;
color: #808080;
letter-spacing: 1px;
font-size: 13px;
margin-top: 25px;
margin-bottom: 46px;

@media ${screens.medium} {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 70px;
}
`;

const ClearButton = styled.button`
font-size: 18px;
color: #02EB51;
background-color: #ffffff;
border: 3px solid #02EB51;
display: block;
margin: 10px auto;
padding: 5px 15px;
cursor: pointer;

@media ${screens.medium} {
  font-size: 24px;
}
`

const RecordScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const phrase = useAppSelector((state) => state.generate.phrase);

  const [recording, setRecording] = useState<string | undefined>(undefined);

  const clearAction = () => {
    setRecording(undefined);
  };

  const disableContinue = !recording;

  const continueAction = () => {
    dispatch(
      GenerateActions.uploadRecording({
        phrase,
        recordingUri: recording,
      }),
    );
  };

  // TODO: Error message if user denies permissions
  return (
    <>
    <PageTitle title="Personal" />
      <StepContainer title={''}>
        <MicrophoneImage src={MICROPHONE_ICON} />
        <Headline>
          Click and <Highlight>hold record</Highlight> button to start
          recording...
        </Headline>
        <GrowContent>
          {!recording && (
            <RecordButton
              onRecordingComplete={(url) => {
                setRecording(url);
              }}
            />
          )}
          {recording && <audio controls src={recording}></audio>}
          {recording && <ClearButton onClick={clearAction}>Clear Recording</ClearButton>}
        </GrowContent>
        <RecordingLengthNotice>*** Recordings are only 5 seconds long ***</RecordingLengthNotice>
      </StepContainer>
      <LinkButton
        to={routes.generate.styles}
        onClick={continueAction}
        disabled={disableContinue}
      >
        Continue
      </LinkButton>
    </>
  );
};
export default RecordScreen;
