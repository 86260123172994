import { ethers } from 'ethers';
import { logger } from '../../../shared/infra/logger';

const metamask = (window as any).ethereum;

const hasMetamask = (): boolean => {
  if (!metamask) {
    return false;
  }
  return metamask.isMetaMask === true;
};

export let provider: ethers.providers.Web3Provider | undefined;
export let signer: ethers.Signer | undefined;

if (hasMetamask()) {
  const metamaskProvider = new ethers.providers.Web3Provider(metamask);
  provider = metamaskProvider;
  signer = metamaskProvider.getSigner();
}

// NOTE: Connect may be the wrong terminology here.
// https://docs.metamask.io/guide/ethereum-provider.html#methods
export const connect = (): Promise<string[]> => {
  if (!metamask) {
    return Promise.reject('MetaMask not found.');
  }

  return metamask.request({ method: 'eth_requestAccounts' }).catch((error) => {
    if (error.code === 4001) {
      // EIP-1193 userRejectedRequest error
      logger.info('Please connect to MetaMask.');
      return Promise.reject('EIP-1193 userRejectedRequest error.');
    } else {
      logger.error(error);
      return Promise.reject('Error when requesting accounts.');
    }
  });
};

// Determines whether or not we have access to a MetaMask account.
// This is intended to be a way to check if we need to requestAccounts.
export const hasAccount = (): Promise<boolean> => {
  if (!metamask) {
    return Promise.reject('MetaMask not found.');
  }

  // NOTE(vple): I couldn't find docs as to whether or not this is the right way to do this check.
  return metamask
    .request({ method: 'eth_accounts' })
    .then((result: string[]) => {
      return result.length > 0;
    })
    .catch((error) => {
      logger.error(error);
      return Promise.reject('Error when checking accounts.');
    });
};

export const onMetaMaskAccountChange = (callback: () => void) => {
  metamask.on('accountsChanged', (accounts) => {
    callback();
  });
};
